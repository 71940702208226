import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { router } from './router/index.js'
import VueDaumMap from 'vue-daum-map'
import VueMeta from 'vue-meta'
import i18n from './i18n'

Vue.config.productionTip = false

Vue.use(VueMeta);

new Vue({
  vuetify,
  router,
  VueDaumMap,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
