<template>
    <v-container class="paInit" fluid>
        <v-row class="" no-gutters>
            <v-col class="" cols="12">
                <v-carousel :height="$vuetify.breakpoint.mobile ? 300 : 820" show-arrows-on-hover cycle hide-delimiter-background>
                    <v-carousel-item
                        v-for="(item, i) in items" :key="i" :src="item.src">
                    </v-carousel-item>
                </v-carousel>
            </v-col>
        </v-row>
        <v-row class="bgColor1" style="padding: 60px 0px;"
            v-if="$vuetify.breakpoint.mobile == false" no-gutters>
            <v-col class="" cols="12">
                <div class="font-weight-bold" style="font-size: 2.6rem; text-align: center;">{{ $t('mainService') }}</div>
            </v-col>
        </v-row>
        <v-row class="bgColor1" style="padding: 20px 0px;"
            v-else-if="$vuetify.breakpoint.mobile == true" no-gutters>
            <v-col class="" cols="12">
                <div class="font-weight-bold" style="font-size: 1.4rem; text-align: center;">주요서비스</div>
            </v-col>
        </v-row>
        <v-row class="bgColor1 pb-4" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-center mb-6" v-for="(crd, i) in cardData" :key="i" :cols="$vuetify.breakpoint.mobile ? 12 : 3">
                <SvcCard v-if="$vuetify.breakpoint.mobile == false" :rfctData="crd"></SvcCard>
                <MobileSvcCard v-else-if="$vuetify.breakpoint.mobile == true" :rfctData="crd"></MobileSvcCard>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </v-container>
</template>

<script>
import SvcCard from './layout/SvcCard.vue';
import MobileSvcCard from './layout/MobileSvcCard.vue';

export default {
    name: 'StartApp',
        
    components: {
        SvcCard,
        MobileSvcCard,
    },
    
    props: {
      
    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {
            this.cardData[0].detail = this.$t('svcCntnt1')
            this.cardData[1].detail = this.$t('svcCntnt2')
            this.cardData[2].detail = this.$t('svcCntnt3')
        })
    },

    computed: {
        getLocale: function () {
            return this.$i18n.locale
        },
    },

    watch:{ 
        getLocale: function () {
            this.cardData[0].detail = this.$t('svcCntnt1')
            this.cardData[1].detail = this.$t('svcCntnt2')
            this.cardData[2].detail = this.$t('svcCntnt3')
        },
    },
        
    methods: {

    },
    
    data: () => ({
        items: [
            { src: require('../assets/mainCarousels/main1.jpg'), },
            { src: require('../assets/mainCarousels/main2.jpg'), },
            { src: require('../assets/mainCarousels/main3.jpg'), },
            { src: require('../assets/mainCarousels/main4.jpg'), },
        ],
        cardData: [
            {
                index: 1, img: require('../assets/service/service1.png'), title: 'Information Technology', path: 'ITPrg',
                detail: '',
            },
            {
                index: 2, img: require('../assets/service/service2.png'), title: 'Food Service', path: 'HarryFood',
                detail: '',
            },
            {
                index: 3, img: require('../assets/service/service3.png'), title: 'Silver Economy', path: 'NrsnHomeDvlp',
                detail: '',
            },
        ],
    }),
};
</script>

<style>
    .paInit { padding: initial !important; }
</style>