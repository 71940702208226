<template>
    <v-row class="ma-0 pa-0" no-gutters>
        <v-spacer></v-spacer>
        <v-col v-if="$vuetify.breakpoint.mobile" class="d-flex justify-center" cols="11">
            <span class="d-inline-block mb-10" style="width: 90%;">
                <span class="d-inline-block rounded-xl itBox1M py-4">
                    <div class="d-flex justify-center mb-2">
                        <v-img :aspect-ratio="23/10" min-width="160" max-width="160" src="../../assets/yoyangSysLogo.png"></v-img>
                    </div>
                    <div class="d-flex justify-center mb-2">
                        <div class="itTxtBox_M d-inline-block rounded-lg py-1">
                            <span class="fontSizeOne">요양시설</span>
                        </div>
                    </div>
                    <div class="d-flex justify-center mb-2">
                        <div class="itTxtBox_M d-inline-block rounded-lg py-1">
                            <span class="fontSizeOne">주야간보호센터</span>
                        </div>
                    </div>
                    <div class="d-flex justify-center mb-2">
                        <div class="itTxtBox_M d-inline-block rounded-lg py-1">
                            <span class="fontSizeOne">방문요양센터</span>
                        </div>
                    </div>
                    <div class="itRightLanceM d-inline-block rounded-lg rounded-l-0">
                    </div>
                    <div class="itRightLanceM d-inline-block rounded-lg rounded-l-0" style="top: 159px; left: 81%; height: 412px;">
                    </div>
                    <div class="d-inline-block" style="width: 25%; height: 2px; position: absolute; bottom: 81px; right: calc(18% + 1px); background-color: #BDBDBD;">
                    </div>
                    <span style="position: absolute; bottom: -20px; left: 50%; transform: translateX(-50%); z-index: 4;">
                        <v-icon style="color: #BDBDBD;">mdi-chevron-up</v-icon>
                    </span>
                    <span style="position: absolute; bottom: 67px; left: -20px; z-index: 4;">
                        <v-icon style="color: #BDBDBD;">mdi-chevron-right</v-icon>
                    </span>
                </span>
                <span class="d-inline-block rounded-xl itBox3M d-flex justify-center mt-6">
                    <span class="fontSizeOneDotTwo">Homepage 구축<br/> 및<br/> Hosting 서비스</span>
                    <span class="d-inline-block itVtcLineM" style=""></span>
                </span>
                <span class="d-inline-block rounded-xl itBox2M py-4 mt-6">
                    <div class="d-inline-block d-flex justify-center align-center mb-4" style="height: 95.64px;">
                        <span class="emblBox d-inline-block rounded-lg py-1 px-2" style="width: 70px; height: 68px;">
                            <span class="fontSizeOneDotTwo font-weight-medium">S V M A R K E T</span>
                        </span>
                        <span class="titleFontSize font-weight-bold ml-3">e-Commerce</span>
                    </div>
                    <div class="d-flex justify-center mb-5">
                        <div class="itTxtBox_M d-inline-block rounded-lg py-1" style>
                            <span class="fontSizeOne">복지용구</span>
                            <span style="position: absolute; bottom: 6px; right: -17px; z-index: 4;">
                                <v-icon style="color: #BDBDBD;">mdi-chevron-left</v-icon>
                            </span>
                        </div>
                    </div>
                    <div class="d-flex justify-center mb-2">
                        <div class="itTxtBox_M d-inline-block rounded-lg py-1">
                            <span class="fontSizeOne">실버용품</span>
                            <span style="position: absolute; bottom: 6px; right: -17px; z-index: 4;">
                                <v-icon style="color: #BDBDBD;">mdi-chevron-left</v-icon>
                            </span>
                        </div>
                    </div>
                    <div class="itRightLanceM d-inline-block rounded-lg rounded-l-0" style="top: 145px; height: 62px;">
                    </div>
                </span>
                <span class="d-inline-block rounded-xl itBox5M d-flex justify-center align-center mt-6">
                    <v-icon class="mr-2 black--text">mdi-animation-outline</v-icon>
                    <span class="fontSizeOneDotTwo">실버관련 데이터 축적</span>
                    <span class="d-inline-block itVtcLineM" style=""></span>
                </span>
                <span class="d-inline-block rounded-xl itBox4M d-flex justify-center mt-6">
                    <span class="fontSizeOneDotTwo">Service Quality 향상<br/> 및<br/> 운영관리 Feedback</span>
                    <span class="d-inline-block itVtcLineM" style=""></span>
                    <div class="itLeftLanceM d-inline-block rounded-lg rounded-r-0"></div>
                </span>
                <v-row class="my-16 pa-0" no-gutters>
                    <v-col class="cardBox3 px-2 mb-10" cols="12">
                        <v-card height="350" style="padding-bottom: 2px;" rounded="lg" outlined>
                            <div class="py-6" style="background-color: #8db4e2; border-radius: initial; text-align: center;">
                                <span class="fontSizeOneDotTwo font-weight-bold white--text">노인장기요앙기관 관리시스템</span>
                            </div>
                            <v-row class="pt-3 ma-0" no-gutters>
                                <v-col class="d-flex justify-center" cols="2">
                                    <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                                </v-col>
                                <v-col class="fontSizeOne" cols="9">
                                    정기평가 체계적 대응
                                </v-col>
                            </v-row>
                            <v-row class="pt-2 ma-0" no-gutters>
                                <v-col class="d-flex justify-center" cols="2">
                                    <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                                </v-col>
                                <v-col class="fontSizeOne" cols="9">
                                    현장중심 소프트웨어 (웹/앱)
                                </v-col>
                            </v-row>
                            <v-row class="pt-2 ma-0" no-gutters>
                                <v-col class="d-flex justify-center" cols="2">
                                    <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                                </v-col>
                                <v-col class="fontSizeOne" cols="9">
                                    가산/감산 시뮬레이션
                                </v-col>
                            </v-row>
                            <v-row class="pt-2 ma-0" no-gutters>
                                <v-col class="d-flex justify-center" cols="2">
                                    <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                                </v-col>
                                <v-col class="fontSizeOne" cols="9">
                                    통신기술을 활용한 편의성 제고
                                </v-col>
                            </v-row>
                            <v-row class="pt-2 ma-0" no-gutters>
                                <v-col class="d-flex justify-center" cols="2">
                                    <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                                </v-col>
                                <v-col class="fontSizeOne" cols="9">
                                    보호자와 원활한 소통
                                </v-col>
                            </v-row>
                            <v-row class="pt-2 pb-3 ma-0" no-gutters>
                                <v-col class="d-flex justify-center" cols="2">
                                    <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                                </v-col>
                                <v-col class="fontSizeOne" cols="9">
                                    기관내 그룹웨어 기능강화
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col class="cardBox2 px-2 mb-10" cols="12">
                        <v-card height="280" style="padding-bottom: 2px;" rounded="lg" outlined>
                            <div class="py-6" style="background-color: #fabf8f; border-radius: initial; text-align: center;">
                                <span class="fontSizeOneDotTwo font-weight-bold white--text">실버용품 e-Commerce</span>
                            </div>
                            <v-row class="pt-5 ma-0" no-gutters>
                                <v-col class="d-flex justify-center" cols="2">
                                    <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                                </v-col>
                                <v-col class="fontSizeOne" cols="9">
                                    재가등급자 복지용구 할인 쇼핑몰
                                </v-col>
                            </v-row>
                            <v-row class="pt-2 ma-0" no-gutters>
                                <v-col class="d-flex justify-center" cols="2">
                                    <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                                </v-col>
                                <v-col class="fontSizeOne" cols="9">
                                    위생용품 정기구매
                                </v-col>
                            </v-row>
                            <v-row class="pt-2 ma-0" no-gutters>
                                <v-col class="d-flex justify-center" cols="2">
                                    <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                                </v-col>
                                <v-col class="fontSizeOne" cols="9">
                                    고가실버용품 공동구매
                                </v-col>
                            </v-row>
                            <v-row class="pt-2 pb-5 ma-0" no-gutters>
                                <v-col class="d-flex justify-center" cols="2">
                                    <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                                </v-col>
                                <v-col class="fontSizeOne" cols="9">
                                    의료용 소모품 제공
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col class="cardBox1 px-2" cols="12">
                        <v-card height="270" style="padding-bottom: 2px;" rounded="lg" outlined>
                            <div class="py-6" style="background-color: #c4d79b; border-radius: initial; text-align: center;">
                                <span class="fontSizeOneDotTwo font-weight-bold white--text">Homepage Service 제공</span>
                            </div>
                            <v-row class="pt-5 ma-0" no-gutters>
                                <v-col class="d-flex justify-center" cols="2">
                                    <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                                </v-col>
                                <v-col class="fontSizeOne" cols="9">
                                    도메인 제공
                                </v-col>
                            </v-row>
                            <v-row class="pt-2 ma-0" no-gutters>
                                <v-col class="d-flex justify-center" cols="2">
                                    <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                                </v-col>
                                <v-col class="fontSizeOne" cols="9">
                                    홈페이지 구축
                                </v-col>
                            </v-row>
                            <v-row class="pt-2 ma-0" no-gutters>
                                <v-col class="d-flex justify-center" cols="2">
                                    <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                                </v-col>
                                <v-col class="fontSizeOne" cols="9">
                                    호스팅 서비스
                                </v-col>
                            </v-row>
                            <v-row class="pt-2 pb-5 ma-0" no-gutters>
                                <v-col class="d-flex justify-center" cols="2">
                                    <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                                </v-col>
                                <v-col class="fontSizeOne" cols="9">
                                    모바일 홈페이지 제공
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <div class="pb-14" style="">
                    <v-img :aspect-ratio="228/100" max-width="100%" src="../../assets/sub/itSubImg.jpg"></v-img>
                </div>
            </span>
        </v-col>
        <v-col v-else class="" cols="8">
            <div class="d-inline-block" style="position: relative; width: 100%; height: 600px;">
                <span class="d-inline-block py-10 rounded-xl itBox1" style="left: 10%; transform: translateX(-10%);">
                    <div class="d-flex justify-center">
                        <v-img :aspect-ratio="23/10" min-width="220" max-width="220" src="../../assets/yoyangSysLogo.png"></v-img>
                    </div>
                    <div class="itTxtBoxL d-inline-block rounded-lg py-1" style="top: 160px;">
                        <span class="titleFontSize">{{ $t('itPrgCntnt1') }}</span>
                    </div>
                    <div class="itTxtBoxL d-inline-block rounded-lg py-1" style="top: 230px;">
                        <span class="titleFontSize">{{ $t('itPrgCntnt2') }}</span>
                    </div>
                    <div class="itTxtBoxL d-inline-block rounded-lg py-1" style="top: 300px;">
                        <span class="titleFontSize">{{ $t('itPrgCntnt3') }}</span>
                    </div>
                    <div class="itRightLance d-inline-block rounded-lg rounded-l-0">
                    </div>
                    <div class="itMidLongLineL d-inline-block"></div>
                    <span style="position: absolute; top: 238px; left: -22px;">
                        <v-icon style="color: #BDBDBD;">mdi-chevron-right</v-icon>
                    </span>
                </span>
                <span class="d-inline-block rounded-xl py-10 itBox2" style="right: 10%; transform: translateX(10%);">
                    <div class="d-inline-block d-flex justify-center align-center" style="height: 95.64px;">
                        <span class="emblBox d-inline-block rounded-lg py-1 px-2">
                            <span class="titleFontSize font-weight-medium">S V M A R K E T</span>
                        </span>
                        <span class="fontSizeOneDotEight font-weight-bold ml-5">e-Commerce</span>
                    </div>
                    <div class="itTxtBoxR d-inline-block rounded-lg py-1" style="top: 160px;">
                        <span class="titleFontSize">{{ $t('itPrgCntnt4') }}</span>
                        <span style="position: absolute; top: 6px; left: -18px;">
                            <v-icon style="color: #BDBDBD;">mdi-chevron-right</v-icon>
                        </span>
                    </div>
                    <div class="itTxtBoxR d-inline-block rounded-lg py-1" style="top: 300px;">
                        <span class="titleFontSize">{{ $t('itPrgCntnt5') }}</span>
                        <span style="position: absolute; top: 6px; left: -18px;">
                            <v-icon style="color: #BDBDBD;">mdi-chevron-right</v-icon>
                        </span>
                    </div>
                    <div class="itLeftLance d-inline-block rounded-lg rounded-r-0">
                    </div>
                    <div class="itMidLongLineR d-inline-block"></div>
                </span>
                <span class="itRightLanceLg d-inline-block rounded-lg rounded-l-0"></span>
                <span class="itLeftLanceLg d-inline-block rounded-lg rounded-r-0"></span>
            </div>
            <div class="d-inline-block" style="position: relative; width: 100%; height: 100px;">
                <span class="d-inline-block rounded-xl itBox3 d-flex justify-center align-center" style="top: 0px; left: 10%; transform: translateX(-10%);">
                    <span class="titleFontSize">{{ $t('itPrgCntnt6') }}</span>
                    <span class="itVtcLine d-inline-block">
                        <span style="position: absolute; top: -12px; left: -11px;">
                            <v-icon style="color: #BDBDBD;">mdi-chevron-up</v-icon>
                        </span>
                    </span>
                </span>
            </div>
            <div class="d-inline-block mt-16" style="position: relative; width: 100%; height: 200px;">
                <span class="d-inline-block rounded-xl itBox4 d-flex justify-center align-center" style="left: 10%; transform: translateX(-10%);">
                    <span class="titleFontSize" style="text-align: center;">{{ $t('itPrgCntnt7') }}</span>
                </span>
                <span class="d-inline-block rounded-xl itBox5 d-flex justify-center align-center" style="right: 12%; transform: translateX(-13%);">
                    <span class="titleFontSize" style="text-align: center;"></span>
                </span>
                <span class="d-inline-block rounded-xl itBox5 d-flex justify-center align-center" style="top: 20px; right: 8%; transform: translateX(-9%);">
                    <span class="titleFontSize" style="text-align: center;"></span>
                </span>
                <span class="d-inline-block rounded-xl itBox5 d-flex justify-center align-center" style="top: 50px; right: 4%; transform: translateX(-5%);">
                    <span class="titleFontSize" style="text-align: center;">{{ $t('itPrgCntnt8') }}</span>
                    <span style="position: absolute; top: 29px; right: -22px;">
                        <v-icon style="color: #BDBDBD;">mdi-chevron-left</v-icon>
                    </span>
                </span>
                <span class="d-inline-block itMidShortLine"></span>
            </div>
            <v-row class="my-16 pa-0" no-gutters>
                <v-col class="cardBox3 px-2" cols="4">
                    <v-card height="370" style="padding-bottom: 2px;" rounded="lg" outlined>
                        <div class="py-6" style="background-color: #8db4e2; border-radius: initial; text-align: center;">
                            <span class="titleFontSize font-weight-bold white--text">{{ $t('itPrgCd1Ttl') }}</span>
                        </div>
                        <v-row class="pt-5 ma-0" no-gutters>
                            <v-col class="d-flex justify-center" cols="2">
                                <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                            </v-col>
                            <v-col cols="9">
                                {{ $t('itPrgCd1List1') }}
                            </v-col>
                        </v-row>
                        <v-row class="pt-2 ma-0" no-gutters>
                            <v-col class="d-flex justify-center" cols="2">
                                <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                            </v-col>
                            <v-col cols="9">
                                {{ $t('itPrgCd1List2') }}
                            </v-col>
                        </v-row>
                        <v-row class="pt-2 ma-0" no-gutters>
                            <v-col class="d-flex justify-center" cols="2">
                                <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                            </v-col>
                            <v-col cols="9">
                                {{ $t('itPrgCd1List3') }}
                            </v-col>
                        </v-row>
                        <v-row class="pt-2 ma-0" no-gutters>
                            <v-col class="d-flex justify-center" cols="2">
                                <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                            </v-col>
                            <v-col cols="9">
                                {{ $t('itPrgCd1List4') }}
                            </v-col>
                        </v-row>
                        <v-row class="pt-2 ma-0" no-gutters>
                            <v-col class="d-flex justify-center" cols="2">
                                <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                            </v-col>
                            <v-col cols="9">
                                {{ $t('itPrgCd1List5') }}
                            </v-col>
                        </v-row>
                        <v-row class="pt-2 pb-5 ma-0" no-gutters>
                            <v-col class="d-flex justify-center" cols="2">
                                <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                            </v-col>
                            <v-col cols="9">
                                {{ $t('itPrgCd1List6') }}
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col class="cardBox2 px-2" cols="4">
                    <v-card height="370" style="padding-bottom: 2px;" rounded="lg" outlined>
                        <div class="py-6" style="background-color: #fabf8f; border-radius: initial; text-align: center;">
                            <span class="titleFontSize font-weight-bold white--text">{{ $t('itPrgCd2Ttl') }}</span>
                        </div>
                        <v-row class="pt-5 ma-0" no-gutters>
                            <v-col class="d-flex justify-center" cols="2">
                                <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                            </v-col>
                            <v-col cols="9">
                                {{ $t('itPrgCd2List1') }}
                            </v-col>
                        </v-row>
                        <v-row class="pt-2 ma-0" no-gutters>
                            <v-col class="d-flex justify-center" cols="2">
                                <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                            </v-col>
                            <v-col cols="9">
                                {{ $t('itPrgCd2List2') }}
                            </v-col>
                        </v-row>
                        <v-row class="pt-2 ma-0" no-gutters>
                            <v-col class="d-flex justify-center" cols="2">
                                <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                            </v-col>
                            <v-col cols="9">
                                {{ $t('itPrgCd2List3') }}
                            </v-col>
                        </v-row>
                        <v-row class="pt-2 pb-5 ma-0" no-gutters>
                            <v-col class="d-flex justify-center" cols="2">
                                <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                            </v-col>
                            <v-col cols="9">
                                {{ $t('itPrgCd2List4') }}
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col class="cardBox1 px-2" cols="4">
                    <v-card height="370" style="padding-bottom: 2px;" rounded="lg" outlined>
                        <div class="py-6" style="background-color: #c4d79b; border-radius: initial; text-align: center;">
                            <span class="titleFontSize font-weight-bold white--text">{{ $t('itPrgCd3Ttl') }}</span>
                        </div>
                        <v-row class="pt-5 ma-0" no-gutters>
                            <v-col class="d-flex justify-center" cols="2">
                                <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                            </v-col>
                            <v-col cols="9">
                                {{ $t('itPrgCd3List1') }}
                            </v-col>
                        </v-row>
                        <v-row class="pt-2 ma-0" no-gutters>
                            <v-col class="d-flex justify-center" cols="2">
                                <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                            </v-col>
                            <v-col cols="9">
                                {{ $t('itPrgCd3List2') }}
                            </v-col>
                        </v-row>
                        <v-row class="pt-2 ma-0" no-gutters>
                            <v-col class="d-flex justify-center" cols="2">
                                <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                            </v-col>
                            <v-col cols="9">
                                {{ $t('itPrgCd3List3') }}
                            </v-col>
                        </v-row>
                        <v-row class="pt-2 pb-5 ma-0" no-gutters>
                            <v-col class="d-flex justify-center" cols="2">
                                <v-icon style="color: #c4d79b;" size="30">mdi-circle-small</v-icon>
                            </v-col>
                            <v-col cols="9">
                                {{ $t('itPrgCd3List4') }}
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <div class="py-16" style="">
                <v-img :aspect-ratio="228/100" max-width="100%" src="../../assets/sub/itSubImg.jpg"></v-img>
            </div>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
</template>

<script>
export default {
    name: 'ITPrg',
        
    components: {

    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        
    },
    
    data: () => ({

    }),
};
</script>

<style>
    .cardBox1 .v-sheet--outlined { border: 4px solid #c4d79b !important; }
    .cardBox2 .v-sheet--outlined { border: 4px solid #fabf8f !important; }
    .cardBox3 .v-sheet--outlined { border: 4px solid #8db4e2 !important; }

    .itBox1 { border: 6px solid #538dd5; position: absolute; width: 42%; height: 400px; top: 100px; z-index: 2; }
    .itBox1M { border: 4px solid #538dd5; width: 100%; height: 250px; position: relative; }
    .itBox2 { border: 6px solid #e26b0a; position: absolute; width: 42%; height: 400px; top: 100px; z-index: 2; }
    .itBox2M { border: 4px solid #e26b0a; width: 100%; height: 250px; position: relative; }
    .itBox3 { border: 6px solid #c4d79b; position: absolute; width: 42%; height: 90px; top: 100px; z-index: 4; }
    .itBox3M { border: 4px solid #c4d79b; width: 100%; height: 96px; text-align: center; position: relative; }
    .itBox4 { border: 6px solid #002060; position: absolute; width: 42%; height: 150px; top: 0px; z-index: 4; background-color: white; }
    .itBox4M { border: 4px solid #002060; width: 100%; height: 96px; text-align: center; position: relative; }
    .itBox5 { border: 6px solid #5D4037; position: absolute; width: 30%; height: 100px; top: -10px; z-index: 4; background-color: white; }
    .itBox5M { border: 4px solid #5D4037; width: 100%; height: 50px; text-align: center; position: relative; }
    .emblBox { width: 76px; height: 74px; line-height: 18px; border: 2px solid #bd0a0a; color: #bd0a0a; }
    .itTxtBoxL {
        border: 2px solid #BDBDBD; position: absolute; left: 20%; transform: translateX(-10%); z-index: 3;
        width: 180px; text-align: center; background-color: white;
    }
    .itTxtBox_M {
        border: 2px solid #BDBDBD; width: 130px; text-align: center; background-color: white; position: relative; z-index: 3;
    }
    .itTxtBoxR {
        border: 2px solid #BDBDBD; position: absolute; right: 20%; transform: translateX(10%); z-index: 3;
        width: 180px; text-align: center; background-color: white;
    }
    .itLeftLance {
        position: absolute; top: 182px; left: 20%; z-index: 2; width: 30%; height: 142px;
        border-top: 2px solid #BDBDBD; border-bottom: 2px solid #BDBDBD; border-left: 2px solid #BDBDBD;
     }
    .itRightLance {
        position: absolute; top: 182px; right: 20%; z-index: 2; width: 30%; height: 142px;
        border-top: 2px solid #BDBDBD; border-bottom: 2px solid #BDBDBD; border-right: 2px solid #BDBDBD;
     }
     .itLeftLanceM {
        position: absolute; bottom: 42px; left: -27px; z-index: 2; width: 8%; height: 626px;
        border-top: 2px solid #BDBDBD; border-bottom: 2px solid #BDBDBD; border-left: 2px solid #BDBDBD;
     }
     .itRightLanceM {
        position: absolute; top: 112px; right: 18%; z-index: 2; width: 25%; height: 96px;
        border-top: 2px solid #BDBDBD; border-bottom: 2px solid #BDBDBD; border-right: 2px solid #BDBDBD;
     }
     .itLeftLanceLg {
        position: absolute; top: 358px; left: 1%; z-index: 1; width: 5%; height: 520px;
        border-top: 2px solid #BDBDBD; border-bottom: 2px solid #BDBDBD; border-left: 2px solid #BDBDBD;
     }
     .itRightLanceLg {
        position: absolute; top: 358px; right: 1%; z-index: 1; width: 5%; height: 520px;
        border-top: 2px solid #BDBDBD; border-bottom: 2px solid #BDBDBD; border-right: 2px solid #BDBDBD;
     }
     .itMidLongLineL {
        position: absolute; top: 252px; left: 20%; z-index: 1;
        width: 90%; height: 2px; background-color: #BDBDBD;
     }
     .itMidLongLineR {
        position: absolute; top: 252px; left: -10%; z-index: 1;
        width: 30%; height: 2px; background-color: #BDBDBD;
     }
     .itMidShortLine {
        position: absolute; top: 60px; left: 50%; transform: translateX(-50%);
        width: 120px; height: 2px; background-color: #BDBDBD;
     }
     .itVtcLine { position: absolute; top: -112px; left: 50%; transform: rotateX(-50%); width: 2px; height: 106px; background-color: #BDBDBD; }
     .itVtcLineM { width: 2px; height: 24px; position: absolute; top: -28px; left: 50%; transform: translateX(-50%); background-color: #BDBDBD; }
</style>