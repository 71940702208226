<template>
    <v-container class="paInit" fluid>
        <v-row class="ma-0 pa-0" v-if="$vuetify.breakpoint.mobile == false" no-gutters>
            <v-col cols="12">
                <div class="subTitleBox d-inline-block d-flex align-center" style="position: relative;">
                    <span class="decoPrllg d-inline-block subDeco1"></span>
                    <span class="decoCircle d-inline-block subDeco1"></span>
                    <span class="decoPrll2 d-inline-block subDeco2"></span>
                    <span class="decoRgBar rounded-pill rounded-l-0 d-inline-block white"></span>
                    <div>
                        <span class="font-weight-bold menuTxrColor--text mb-4"
                            style="margin-left: 120px; font-size: 2.2rem !important; display: inherit;">
                            <span v-if="$parent.$parent.$parent.routerRocation == 'NrsnHomeDvlp'">{{ $t('subTtl2') }}</span>
                            <span v-else-if="$parent.$parent.$parent.routerRocation == 'SlvrBsns'">{{ $t('subTtl3') }}</span>
                        </span>
                        <span class="font-weight-thin menuTxrColor--text"
                            style="margin-left: 120px; font-size: 1.1rem !important; display: inherit;">
                            <span class="mr-1">{{ $t('cmpCorp') }}</span>
                            <span v-if="$parent.$parent.$parent.routerRocation == 'NrsnHomeDvlp'">{{ $t('subTtl2') }}</span>
                            <span v-else-if="$parent.$parent.$parent.routerRocation == 'SlvrBsns'">{{ $t('subTtl3') }}</span>
                        </span>
                    </div>
                    <v-spacer></v-spacer>
                    <span class="d-inline-block subDecoImage2"></span>
                </div>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" v-else-if="$vuetify.breakpoint.mobile == true" no-gutters>
            <v-col cols="12">
                <div class="subTitleBoxMobile2" style="margin-bottom: 1px;">
                    
                </div>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-0 pa-0" v-if="$vuetify.breakpoint.mobile == false" no-gutters>
            <v-col cols="12">
                <div class="d-flex justify-center" v-if="$parent.$parent.$parent.routerRocation != 'ServicePolicies' && $parent.$parent.$parent.routerRocation != 'PrvcPlcy'">
                    <v-btn height="65"
                        :class="['sub-button fontSizeOneDotTwo rounded-0 elmBl elmBr px-8', subTitle[0] == true ? 'pointColor white--text' : '']"
                        :ripple="false" @click="$parent.$parent.$parent.$refs.appHeader.routerAction('NrsnHomeDvlp')" text>{{ $t('subTtl2') }}</v-btn>
                    <v-btn height="65"
                        :class="['sub-button fontSizeOneDotTwo rounded-0 elmBr px-8', subTitle[1] == true ? 'pointColor white--text' : '']"
                        :ripple="false" @click="$parent.$parent.$parent.$refs.appHeader.routerAction('SlvrBsns')" text>{{ $t('subTtl3') }}</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="font-weight-bold menuTxrColor--text fontSizeOneDotEight pl-7 my-4"
            v-if="$vuetify.breakpoint.mobile == true">
            <span class="pl-4" style="border-left: 5px solid #f7ae22;"></span>
            <span v-if="$parent.$parent.$parent.routerRocation == 'NrsnHomeDvlp'">요양원 개발</span>
            <span v-else-if="$parent.$parent.$parent.routerRocation == 'SlvrBsns'">실버사업</span>
        </div>
        <v-divider v-if="$vuetify.breakpoint.mobile == true"></v-divider>
        <router-view class="mt-1"></router-view>
    </v-container>
</template>

<script>
export default {
    name: 'BizCnfgr',
        
    components: {

    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {
            this.subTitleControll(this.$parent.$parent.$parent.routerRocation)
        })
    },

    computed: {
        getRouterInfo: function () {
            return this.$parent.$parent.$parent.routerRocation
        },
    },

    watch:{
        getRouterInfo: function (routerName) {
            this.subTitleControll(routerName)
        },
    },
        
    methods: {
        subTitleControll: function (routerName) {
            for (let index = 0; index < this.subTitle.length; index++) {
                this.subTitle.splice(index, 1, false)
            }
            switch (routerName) {
                case 'NrsnHomeDvlp':
                    this.subTitle.splice(0, 1, true)
                    break;
                case 'SlvrBsns':
                    this.subTitle.splice(1, 1, true)
                    break;
                default:
                    break;
            }
        },
    },
    
    data: () => ({
        subTitle: [false, false, false, false, false, false],
    }),
};
</script>

<style>
    .subTitleBoxMobile2 { 
        width: 100%; height: 200px; opacity: 0.8;
        background-image: url('../../assets/sub/architecture.jpg'); background-size: cover;
    }
    .subDecoImage2 {
        width: 467px; height: 100%;
        background: linear-gradient(106deg, rgba(255,255,255,1) 15%, rgba(254,244,234,0) 50%, rgba(255,255,255,1) 95%)
        , url('../../assets/sub/architecture.jpg');
        background-size: cover;
    }
</style>