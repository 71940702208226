<template>
    <!-- <v-row class="my-5 mx-14" no-gutters>
        <v-col class="" cols="8">
            <div class="d-flex align-center">
                <span class="d-inline-block mr-7" style="width: 40px;">
                    <span class="d-inline-block d-inline-block d-flex align-center appBarSubMenu">
                        <v-img
                        alt="harrygnd Logo"
                        class="shrink mr-2" width="40"
                        src="../../assets/harrygndLogo.png"
                        transition="scale-transition" contain>
                        </v-img>
                    </span>
                </span>
                <span>
                    <span class="d-block" style="font-size: 0.875rem;">
                        상호명: ㈜해리지앤디 | 전화 031-546-0405 | 팩스 031-895-0405 | information@harrygnd.com
                    </span>
                    <span class="d-block" style="font-size: 0.875rem;">
                        소재지: 경기도 수원시 반달로 7번길 40 7층 (영통동 998-1) | 대표: 이승준 | 사업자등록 번호: 762-81-01713
                    </span>
                    <span class="d-block font-weight-thin grey--text darken-1" style="font-size: 0.75rem;">
                        Copyright 2023 ⓒ ㈜해리지앤디. All right reserved.
                    </span> 
                </span>
            </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex align-center" cols="4">
            <span class="d-inline-block">
                <v-btn class="font-weight-bold" @click="$parent.$parent.$parent.$refs.appHeader.routerAction('CmpInt')" text>회사소개</v-btn>
            </span>
            <span class="d-inline-block">
                <v-btn class="font-weight-bold" @click="$parent.$parent.$parent.$refs.appHeader.routerAction('CmpWayCome')" text>오시는길</v-btn>
            </span>
            <span class="d-inline-block">
                <v-btn class="font-weight-bold" @click="$parent.$parent.$parent.$refs.appHeader.routerAction('ServicePolicies')" text>이용약관</v-btn>
            </span>
            <span class="d-inline-block">
                <v-btn class="font-weight-bold" @click="$parent.$parent.$parent.$refs.appHeader.routerAction('PrvcPlcy')" text>개인정보취급정책</v-btn>
            </span>
        </v-col>
    </v-row> -->
    <v-row class="ma-0" style="padding: 30px 0px;" no-gutters>
        <v-spacer></v-spacer>
        <v-col class="d-flex" cols="10">
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="d-flex" cols="2">
                    <div>
                        <div>
                            <v-btn class="font-weight-light mb-2"
                                style="font-size: 1rem !important; letter-spacing: initial;"
                                @click="$parent.$parent.$parent.$refs.appHeader.routerAction('CmpInt')" text>
                                {{ $t('footMenu1') }}
                            </v-btn>
                        </div>
                        <div>
                            <v-btn class="font-weight-light mb-2"
                                style="font-size: 1rem !important; letter-spacing: initial;"
                                @click="$parent.$parent.$parent.$refs.appHeader.routerAction('CmpWayCome')" text>
                                {{ $t('footMenu2') }}
                            </v-btn>
                        </div>
                        <div>
                            <v-btn class="font-weight-light mb-2"
                                style="font-size: 1rem !important; letter-spacing: initial;"
                                @click="$parent.$parent.$parent.$refs.appHeader.routerAction('ServicePolicies')" text>
                                {{ $t('footMenu3') }}
                            </v-btn>
                        </div>
                        <div>
                            <v-btn class="font-weight-light mb-2" 
                                style="font-size: 1rem !important; letter-spacing: initial;" 
                                @click="$parent.$parent.$parent.$refs.appHeader.routerAction('PrvcPlcy')" text>
                                {{ $t('footMenu4') }}
                            </v-btn>
                        </div> 
                    </div>
                </v-col>
                <v-col class="" cols="1">
                    <v-divider vertical></v-divider>
                </v-col>
                <v-col class="" cols="4">
                    <div class="mb-1" style="color: #292929; font-size: 1rem;">{{ $t('cmpAddr') }}</div>
                    <div class="mb-6" style="color: #292929; font-size: 1rem;">{{ $t('bsnsRgnmb') }}&nbsp;&nbsp;{{ $t('bsnsRgnmbNum') }}</div>
                    <div class="mb-1" style="color: #292929; font-size: 1rem;">{{ $t('ceo') }}&nbsp;&nbsp;{{ $t('ceoNm') }}</div>
                    <div class="mb-1" style="color: #292929; font-size: 1rem;">{{ $t('tel') }}&nbsp;&nbsp;{{ $t('telNum') }}</div>
                    <div class="mb-1" style="color: #292929; font-size: 1rem;">{{ $t('fax') }}&nbsp;&nbsp;{{ $t('faxNum') }}</div>
                    <div class="mb-6" style="color: #292929; font-size: 1rem;">{{ $t('email') }}&nbsp;&nbsp;{{ $t('emailAddr') }}</div>
                    <div class="grey--text darken-4 mb-6" style="font-size: 0.875rem;">Copyright 2023 ⓒ {{ $t('copyRightCmp') }}. All right reserved.</div>
                </v-col>
                <v-col class="d-flex align-center" cols="5">
                    <v-spacer></v-spacer>
                    <div class="d-inline-block" style="width: 92px;">
                        <div class="mb-12">
                            <v-img max-width="100" max-height="100" src="../../assets/harrygndLogo.png" alt="해리지앤디 로고"></v-img>
                        </div>
                        <div>
                            <v-switch
                                v-model="lctSwtc"
                                :label="lctSwtc == true ? 'English' : '한국어'"
                                @click="setLocale()">
                            </v-switch>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
</template>

<script>
export default {
    name: 'CmFooter',
        
    components: {

    },
    
    props: {

    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {
            if(localStorage.getItem("hryLocale") == 'en' || localStorage.getItem("hryLocale") == 'ko'){
                if(localStorage.getItem("hryLocale") == 'en')
                    this.lctSwtc = true
                else
                    this.lctSwtc = false

                this.$i18n.locale = localStorage.getItem("hryLocale")
            }
        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        setLocale: function () {
            if(this.lctSwtc)
                this.$i18n.locale = 'en'
            else
                this.$i18n.locale = 'ko'

            localStorage.setItem('hryLocale', this.$i18n.locale)
        },
    },
    
    data: () => ({
        hover: false,
        lctSwtc: false,
    }),
};
</script>