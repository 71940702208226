<template>
    <vue-daum-map
        :class="[$vuetify.breakpoint.mobile == false ? 'mapSize' : 'mapSizeMobile']"

        :appKey="appKey"
        :center.sync="center"
        :level.sync="level"
        :mapTypeId="mapTypeId"
        :libraries="libraries"

        @load="onLoad">
    </vue-daum-map>
</template>

<script>
import VueDaumMap from 'vue-daum-map';

export default {
    name: 'KaKaoMap',
        
    components: {
        VueDaumMap,
    },

    props: {
        location: { type: Object, default: new Object() },
    },

    created: function(){
        this.center.lat = this.location.lat
        this.center.lng = this.location.lng
    },

    mounted: function(){
        this.$nextTick(function () {
            this.center.lat = this.location.lat
            this.center.lng = this.location.lng
        })
    },

    computed: {
        getMoblieMode: function () {
            return this.$vuetify.breakpoint.mobile
        },
    },

    watch:{ 
        getMoblieMode: function () {
            this.$router.go(0)
        },
    },
        
    methods: {
        // 지도가 로드 완료되면 load 이벤트 발생
        onLoad: function (map, daum) {
            this.map = map
            this.daum = daum

            // 위치표시를 위한 마커생성
            let marker = new daum.maps.Marker({
                //position: daum.maps.setLatLng(this.center.lat, this.center.lng),
                position: map.getCenter(),
                map: map
            });

            // 마커 적용
            marker.setMap(map)
        },

    },
    
    data: () => ({
        markers: [],
        appKey: '386c0bea291e5d80f731b8be12fbc92d',                     // appkey
        center: { lat: 0, lng: 0 },                                     // 지도의 중심 좌표
        level: 3,                                                       // 지도의 레벨(확대, 축소 정도),
        mapTypeId: VueDaumMap.MapTypeId.NORMAL,                         // 맵 타입
        libraries: [],                                                  // 추가로 불러올 라이브러리
        map: null,                                                      // 지도 객체. 지도가 로드되면 할당됨.
        daum: null,                                                     // 다음 API 객체. 지도가 로드되면 할당됨.
    }),
};
</script>

<style>
    .mapSize { width: 100%; height: 400px; }
    .mapSizeMobile { width: 100%; height: 200px; }
</style>