<template>
    <div>
        <v-row class="pa-0 px-2 mt-2" no-gutters>
            <v-col class="" cols="2">
                <v-icon class="menuTxrColor--text" style="padding-bottom: 2px;" size="20">mdi-subway-variant</v-icon>
                <span class="ml-1 menuTxrColor--text font-weight-medium">{{ $t('cmpSubway') }}</span>
            </v-col>
            <v-col class="px-2" cols="9">
                <div class="menuTxrColor--text" v-for="(item, i) in trafficData.subWay" :key="i">{{ item }}</div>
            </v-col>
        </v-row>
        <v-row class="pa-0 px-2 mt-2" no-gutters>
            <v-col class="" cols="2">
                <v-icon class="menuTxrColor--text" style="padding-bottom: 2px;" size="20">mdi-bus</v-icon>
                <span class="ml-1 menuTxrColor--text font-weight-medium">{{ $t('cmpBus') }}</span>
            </v-col>
            <v-col class="px-2" cols="9">
                <div class="menuTxrColor--text" v-for="(item, i) in trafficData.bus" :key="i">{{ item }}</div>
            </v-col>
        </v-row>
        <v-row class="pa-0 px-2 mt-2" v-if="trafficData.airportLimousine.length != 0" no-gutters>
            <v-col class="" cols="2">
                <v-icon class="menuTxrColor--text" style="padding-bottom: 2px;" size="20">mdi-airplane-landing</v-icon>
                <span class="ml-1 menuTxrColor--text font-weight-medium">공항버스</span>
            </v-col>
            <v-col class="px-2" cols="9">
                <div class="menuTxrColor--text" v-for="(item, i) in trafficData.airportLimousine" :key="i">{{ item }}</div>
            </v-col>
        </v-row>
        <v-row class="pa-0 px-2 mt-2" no-gutters>
            <v-col class="" cols="2">
                <v-icon class="grey0999--text" style="padding-bottom: 2px;" size="20">mdi-parking</v-icon>
                <span class="ml-1 grey0999--text font-weight-medium">{{ $t('cmpParkingInf') }}</span>
            </v-col>
            <v-col class="px-2" cols="9">
                <div class="grey0999--text" v-for="(item, i) in trafficData.parking" :key="i">{{ item }}</div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'TraicInfo',
        
    components: {

    },

    props: {
        trafficData: { type: Object, default: new Object() },
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        
    },
    
    data: () => ({

    }),
};
</script>