<template>
    <v-card
        class="" min-width="320"
        :img="rfctData.img" @mouseover="onCardOverEvent()" @mouseleave="outCardOverEvent()"
        width="320" height="400" elevation="0" rounded="lg">
        <transition name="fade" mode="in-out">
            <div v-if="!hover" class="white--text font-weight-bold d-flex justify-center"
                style="position: absolute; bottom: 16px; left: 0px; z-index: 3; font-size: 1.2rem !important; width: 100%;">
                {{ rfctData.title }}
            </div>
        </transition>
        <div
            class="pointColor rounded-lg rounded-t-0 serviceCard"
            style="position: absolute; bottom: 0px;">
        </div>
        <v-expand-transition mode="out-in">
            <div v-if="hover" style="position: absolute; bottom: 60px;"
                class="rounded-lg rounded-b-0 serviceCard--active">
                <div class="white--text font-weight-black mt-10" style="text-align: center; font-size: 2rem;">
                    {{ rfctData.title }}
                </div>
                <v-divider class="white" style="padding: 3px 0px; margin: 12px 140px 24px 140px;"></v-divider>
                <div class="white--text font-weight-Thin mx-4" style="text-align: center; font-size: 1rem !important;">
                    {{ rfctData.detail }}
                </div>
                <div class="d-flex justify-center align-center mt-4">
                    <v-btn
                        class="ma-2" color="white" @click="routerAction(rfctData.path)"
                        outlined rounded>
                        {{ $t('lrnMore') }}
                    </v-btn>
                </div>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
export default {
    name: 'SvcCard',
        
    components: {

    },
    
    props: {
        rfctData: { type: Object, default: new Object() }
    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        onCardOverEvent: function () {
            this.hover = true
        },
        outCardOverEvent: function () {
            this.hover = false
        },
        routerAction: function (path) {
            this.$router.push({ name: path, });
        },
    },
    
    data: () => ({
        hover: false,
    }),
};
</script>

<style>
    .serviceCard { width: 320px; height: 60px; opacity: 0.8; }
    .serviceCard--active { width: 320px; height: 340px; background-color: rgba( 247, 174, 34, 0.8 ); }

    .fade-enter { opacity: 0; }

    .fade-enter-active { transition: opacity 0.2s step-end; }

    .fade-leave-active { transition: opacity 1ms step-start; }
    .fade-leave-to { opacity: 0; }

</style>