<template>
    <v-row class="ma-0 pa-0" no-gutters>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-center" :cols="$vuetify.breakpoint.mobile ? 10 : 8">
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col v-if="!$vuetify.breakpoint.mobile" class="" cols="12">
                    <div class="d-inline-block d-flex justify-center align-center" style="width: 100%; height: 740px; position: relative;">
                        <span style="position: absolute; top: 38%; left: 50%; transform: translate(-50%, -38%);">
                            <span class="d-inline-block pieChart" style="top: 50px; z-index: 3;">
                                <span class="pieChartInner"></span>
                            </span>
                        </span>
                        <span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                            <span class="d-inline-block" style="width: 420px; height: 420px; border: 1px solid #9f9f9f; border-radius: 50%;">
                                <span class="d-inline-block d-flex justify-center align-center"
                                    style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 150px; height: 150px;">
                                    <v-img src="../../assets/harrygndLogoBig.png" alt="해리지앤디 로고"></v-img>
                                </span>
                                <!-- circle number start -->
                                <span class="d-inline-block d-flex justify-center align-center stltNum"
                                    style="top: -25px; left: 50%; transform: translateX(-50%);">
                                    <span class="white--text" style="font-size: 1.5rem;">01</span>
                                    <span style="position: relative;">
                                        <span class="d-inline-block"
                                            style="position: absolute; top: -100px; left: -106px; width: 182px; height: 40px;">
                                            <div class="font-weight-medium" style="font-size: 1.6rem; color: #3c3c3c; text-align: center;">
                                                {{ $t('slvrTtl1') }}
                                            </div>
                                            <div class="" style="font-size: 1rem; color: #3c3c3c;">
                                                {{ $t('slvrCntnt1') }}
                                            </div>
                                        </span>
                                    </span>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center stltNum"
                                    style="top: 22%; right: 2px; transform: translateY(-22%);">
                                    <span class="white--text" style="font-size: 1.5rem;">02</span>
                                    <span style="position: relative;">
                                        <span class="d-inline-block"
                                            style="position: absolute; top: -46px; right: -190px; width: 150px; height: 40px;">
                                            <div class="font-weight-medium" style="font-size: 1.6rem; color: #3c3c3c; text-align: center;">
                                                {{ $t('slvrTtl2') }}
                                            </div>
                                            <div class="" style="font-size: 1rem; color: #3c3c3c;">
                                                {{ $t('slvrCntnt2') }}
                                            </div>
                                        </span>
                                    </span>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center stltNum"
                                    style="top: 78%; right: 2px; transform: translateY(-78%);">
                                    <span class="white--text" style="font-size: 1.5rem;">03</span>
                                    <span style="position: relative;">
                                        <span class="d-inline-block "
                                            style="position: absolute; top: -38px; right: -190px; width: 150px; height: 40px;">
                                            <div class="font-weight-medium" style="font-size: 1.6rem; color: #3c3c3c; text-align: center;">
                                                {{ $t('slvrTtl3') }}
                                            </div>
                                            <div class="" style="font-size: 1rem; color: #3c3c3c;">
                                                {{ $t('slvrCntnt3') }}
                                            </div>
                                        </span>
                                    </span>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center stltNum"
                                    style="bottom: -20px; left: 50%; transform: translateX(-50%);">
                                    <span class="white--text" style="font-size: 1.5rem;">04</span>
                                    <span style="position: relative;">
                                        <span class="d-inline-block"
                                            style="position: absolute; bottom: -70px; left: -148px; width: 295px; height: 40px;">
                                            <div class="font-weight-medium pr-7" style="font-size: 1.6rem; color: #3c3c3c; text-align: center;">
                                                {{ $t('slvrTtl4') }}
                                            </div>
                                            <div class="" style="font-size: 1rem; color: #3c3c3c;">
                                                {{ $t('slvrCntnt4') }}
                                            </div>
                                        </span>
                                    </span>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center stltNum"
                                    style="top: 78%; left: 2px; transform: translateY(-78%);">
                                    <span class="white--text" style="font-size: 1.5rem;">05</span>
                                    <span style="position: relative;">
                                        <span class="d-inline-block"
                                            style="position: absolute; top: -46px; left: -250px; width: 192px; height: 40px;">
                                            <div class="font-weight-medium" style="font-size: 1.6rem; color: #3c3c3c; text-align: center;">
                                                {{ $t('slvrTtl5') }}
                                            </div>
                                            <div class="" style="font-size: 1rem; color: #3c3c3c;">
                                                {{ $t('slvrCntnt5') }}
                                            </div>
                                        </span>
                                    </span>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center stltNum"
                                    style="top: 22%; left: 2px; transform: translateY(-22%);">
                                    <span class="white--text" style="font-size: 1.5rem;">06</span>
                                    <span style="position: relative;">
                                        <span class="d-inline-block"
                                            style="position: absolute; top: -46px; left: -250px; width: 192px; height: 40px;">
                                            <div class="font-weight-medium" style="font-size: 1.6rem; color: #3c3c3c; text-align: center;">
                                                {{ $t('slvrTtl6') }}
                                            </div>
                                            <div class="" style="font-size: 1rem; color: #3c3c3c;">
                                                {{ $t('slvrCntnt6') }}
                                            </div>
                                        </span>
                                    </span>
                                </span>
                                <!-- circle number end -->
                                <!-- circle arrow start -->
                                <span class="d-inline-block d-flex justify-center align-center"
                                    style="position: absolute; top: 9px; right: 70px; width: 50px; height: 50px; transform:rotate(38deg);">
                                    <v-icon size="30" style="color: #9f9f9f;">mdi-chevron-right</v-icon>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center"
                                    style="position: absolute; top: 42%; right: -24px; transform: translateY(-42%); width: 50px; height: 50px; transform:rotate(88deg);">
                                    <v-icon size="30" style="color: #9f9f9f;">mdi-chevron-right</v-icon>
                                </span>
                                
                                <span class="d-inline-block d-flex justify-center align-center"
                                    style="position: absolute; bottom: 14px; right: 72px; width: 50px; height: 50px; transform:rotate(-38deg);">
                                    <v-icon size="30" style="color: #9f9f9f;">mdi-chevron-left</v-icon>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center"
                                    style="position: absolute; bottom: 14px; left: 72px; width: 50px; height: 50px; transform:rotate(31deg);">
                                    <v-icon size="30" style="color: #9f9f9f;">mdi-chevron-left</v-icon>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center"
                                    style="position: absolute; top: 44%; left: -25px; transform: translateY(-44%); width: 50px; height: 50px; transform:rotate(-2deg);">
                                    <v-icon size="30" style="color: #9f9f9f;">mdi-chevron-up</v-icon>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center"
                                    style="position: absolute; top: 9px; left: 70px; width: 50px; height: 50px; transform:rotate(-38deg);">
                                    <v-icon size="30" style="color: #9f9f9f;">mdi-chevron-right</v-icon>
                                </span>
                                <!-- circle arrow end -->
                            </span>
                        </span>
                    </div>
                </v-col>
                <v-col v-else class="" cols="12">
                    <div class="d-inline-block d-flex justify-center align-center" style="width: 100%; height: 500px; position: relative;">
                        <span style="position: absolute; top: 31%; left: 50%; transform: translate(-50%, -31%);">
                            <span class="d-inline-block pieChartM" style="top: 50px; z-index: 3;">
                                <span class="pieChartInnerM"></span>
                            </span>
                        </span>
                        <span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                            <span class="d-inline-block" style="width: 350px; height: 350px; border: 1px solid #9f9f9f; border-radius: 50%;">
                                <span class="d-inline-block d-flex justify-center align-center"
                                    style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100px; height: 100px;">
                                    <v-img src="../../assets/harrygndLogoBig.png" alt="해리지앤디 로고"></v-img>
                                </span>
                                <!-- circle number start -->
                                <span class="d-inline-block d-flex justify-center align-center stltNum"
                                    style="top: -25px; left: 50%; transform: translateX(-50%);">
                                    <span class="white--text" style="font-size: 1.5rem;">01</span>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center stltNum"
                                    style="top: 22%; right: 2px; transform: translateY(-22%);">
                                    <span class="white--text" style="font-size: 1.5rem;">02</span>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center stltNum"
                                    style="top: 78%; right: 2px; transform: translateY(-78%);">
                                    <span class="white--text" style="font-size: 1.5rem;">03</span>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center stltNum"
                                    style="bottom: -20px; left: 50%; transform: translateX(-50%);">
                                    <span class="white--text" style="font-size: 1.5rem;">04</span>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center stltNum"
                                    style="top: 78%; left: 2px; transform: translateY(-78%);">
                                    <span class="white--text" style="font-size: 1.5rem;">05</span>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center stltNum"
                                    style="top: 22%; left: 2px; transform: translateY(-22%);">
                                    <span class="white--text" style="font-size: 1.5rem;">06</span>
                                </span>
                                <!-- circle number end -->
                                <!-- circle arrow start -->
                                <span class="d-inline-block d-flex justify-center align-center"
                                    style="position: absolute; top: 0px; right: 62px; width: 50px; height: 50px; transform:rotate(38deg);">
                                    <v-icon size="30" style="color: #9f9f9f;">mdi-chevron-right</v-icon>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center"
                                    style="position: absolute; top: 42%; right: -24px; transform: translateY(-42%); width: 50px; height: 50px; transform:rotate(88deg);">
                                    <v-icon size="30" style="color: #9f9f9f;">mdi-chevron-right</v-icon>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center"
                                    style="position: absolute; bottom: 6px; right: 62px; width: 50px; height: 50px; transform:rotate(-38deg);">
                                    <v-icon size="30" style="color: #9f9f9f;">mdi-chevron-left</v-icon>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center"
                                    style="position: absolute; bottom: 3px; left: 68px; width: 50px; height: 50px; transform:rotate(30deg);">
                                    <v-icon size="30" style="color: #9f9f9f;">mdi-chevron-left</v-icon>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center"
                                    style="position: absolute; top: 44%; left: -25px; transform: translateY(-44%); width: 50px; height: 50px; transform:rotate(-2deg);">
                                    <v-icon size="30" style="color: #9f9f9f;">mdi-chevron-up</v-icon>
                                </span>
                                <span class="d-inline-block d-flex justify-center align-center"
                                    style="position: absolute; top: 0px; left: 60px; width: 50px; height: 50px; transform:rotate(-28deg);">
                                    <v-icon size="30" style="color: #9f9f9f;">mdi-chevron-right</v-icon>
                                </span>
                                <!-- circle arrow end -->
                            </span>
                        </span>
                    </div>
                    <div class="mb-16">
                        <div class="mb-6">
                            <div class="font-weight-medium" style="font-size: 1.6rem; color: #3c3c3c;">
                                01. 컨설팅
                            </div>
                            <div class="" style="font-size: 1rem; color: #3c3c3c;">
                                요양원 건축, 매매, 파이낸싱
                            </div>
                        </div>
                        <div class="mb-6">
                            <div class="font-weight-medium" style="font-size: 1.6rem; color: #3c3c3c;">
                                02. 배상보험
                            </div>
                            <div class="" style="font-size: 1rem; color: #3c3c3c;">
                                요양원 배상책임보험 대리점
                            </div>
                        </div>
                        <div class="mb-6">
                            <div class="font-weight-medium" style="font-size: 1.6rem; color: #3c3c3c;">
                                03. 재활장비
                            </div>
                            <div class="" style="font-size: 1rem; color: #3c3c3c;">
                                재활장비 판매 및 렌탈
                            </div>
                        </div>
                        <div class="mb-6">
                            <div class="font-weight-medium" style="font-size: 1.6rem; color: #3c3c3c;">
                                04. 렌탈사업
                            </div>
                            <div class="" style="font-size: 1rem; color: #3c3c3c;">
                                기존자산(침대, 각종 기자재)의 유동화를 통한 자금지원 / 공기청정기, 정수기 등의 렌탈
                            </div>
                        </div>
                        <div class="mb-6">
                            <div class="font-weight-medium" style="font-size: 1.6rem; color: #3c3c3c;">
                                05. e-Commerce
                            </div>
                            <div class="" style="font-size: 1rem; color: #3c3c3c;">
                                실버 관련 위생용품, 의료장비 등 각종 용품 인터넷 거래
                            </div>
                        </div>
                        <div class="mb-6">
                            <div class="font-weight-medium" style="font-size: 1.6rem; color: #3c3c3c;">
                                06. e-홍보대행
                            </div>
                            <div class="" style="font-size: 1rem; color: #3c3c3c;">
                                요양원 홈페이지 구축, 마케팅 대행, 블로그 작업
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
</template>

<script>
export default {
    name: 'SlvrBsns',
        
    components: {

    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        
    },
    
    data: () => ({

    }),
};
</script>

<style>
    .stltNum { position: absolute; width: 50px; height: 50px; border-radius: 50%; background-color: #bebebe; border: 4px solid #5a5a5a; }

    .pieChartM { position: relative; width: 250px; height: 250px; border-radius: 50%; transition: 0.1s; animation: pie 0.5s forwards; }
    .pieChartInnerM { 
        background: #fff; position: absolute; top: 50%; left: 50%; width: 170px; height: 170px;
        border-radius: 50%; text-align: center; line-height: 100px; font-size: 30px;
        transform: translate(-50%, -50%);
    }

    .pieChart { position: relative; width: 320px; height: 320px; border-radius: 50%; transition: 0.1s; animation: pie 0.5s forwards; }
    .pieChartInner { 
        background: #fff; position: absolute; top: 50%; left: 50%; width: 240px; height: 240px;
        border-radius: 50%; text-align: center; line-height: 100px; font-size: 30px;
        transform: translate(-50%, -50%);
    }
    @keyframes pie{
        0% { background : conic-gradient(#bfbfbf 0% 0%, #ffffff 0% 100%) }
        6% { background : conic-gradient(#bfbfbf 0% 6%, #ffffff 0% 100%) }
        12% { background : conic-gradient(#bfbfbf 0% 12%, #ffffff 0% 100%) }
        18% { background : conic-gradient(#bfbfbf 0% 18%, #ffffff 0% 100%) }
        25% { background : conic-gradient(#bfbfbf 0% 25%, #ffffff 0% 100%) }
        33% { background : conic-gradient(#bfbfbf 0% 33%, #ffffff 0% 100%) }
        38% { background : conic-gradient(#bfbfbf 0% 38%, #ffffff 0% 100%) }
        44% { background : conic-gradient(#bfbfbf 0% 44%, #ffffff 0% 100%) }
        50% { background : conic-gradient(#bfbfbf 0% 50%, #ffffff 0% 100%) }
        56% { background : conic-gradient(#bfbfbf 0% 56%, #ffffff 0% 100%) }
        62% { background : conic-gradient(#bfbfbf 0% 62%, #ffffff 0% 100%) }
        68% { background : conic-gradient(#bfbfbf 0% 68%, #ffffff 0% 100%) }
        75% { background : conic-gradient(#bfbfbf 0% 75%, #ffffff 0% 100%) }
        82% { background : conic-gradient(#bfbfbf 0% 82%, #ffffff 0% 100%) }
        88% { background : conic-gradient(#bfbfbf 0% 88%, #ffffff 0% 100%) }
        94% { background : conic-gradient(#bfbfbf 0% 94%, #ffffff 0% 100%) }
        100% { background : conic-gradient(#bfbfbf 0% 100%, #ffffff 100% 100%) }
    }
</style>