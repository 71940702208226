<template>
    <v-row class="ma-0 pa-0 py-8" no-gutters>
        <v-spacer></v-spacer>
        <v-col cols="10">
            <div class="px-2">{{ $t('cmpCorp') }}</div>
            <div class="px-2 pointColor--text font-weight-bold fontSizeOneDotEight">
                {{ $t('cmpAddr') }}
            </div>
            <v-divider class="mb-6 mt-3"></v-divider>
            <KaKaoMap :location="mapCenterPosn[1]"></KaKaoMap>
            <v-divider class="mb-6 mt-3"></v-divider>
            <TraicInfo v-if="$vuetify.breakpoint.mobile == false" :trafficData="$i18n.locale == 'en' ? trafficInfEn[1] : trafficInfKo[1]"></TraicInfo>
            <TraicInfoMobile v-else-if="$vuetify.breakpoint.mobile == true" :trafficData="trafficInfoMobile" :target=1></TraicInfoMobile>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
</template>

<script>
import KaKaoMap from '../commons/KaKaoMap.vue';
import TraicInfo from './TraicInfo.vue';
import TraicInfoMobile from './TraicInfoMobile.vue';

export default {
    name: 'CmpWayCome',
        
    components: {
        KaKaoMap,
        TraicInfo,
        TraicInfoMobile,
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        
    },
    
    data: () => ({
        mapCenterPosn: [
            { lat: 37.27139434170729, lng: 127.12774660343013 },
            { lat: 37.253530265119636, lng: 127.07520238394683 },
        ],
        trafficInfKo: [
            { 
                subWay: ['기흥역: 분당선 기흥역에서 도보 20분 거리', '강남대역: 기흥역에서 에버라인으로 환승 1정거장 후 강남대역'],
                bus: ['5000, 5005: 서울역 - 백병원 - 강남대역 - 명지대앞', '5001, 5003: 강남역 - 양재역 - 강남대역 - 명지대앞',
                    '5600: 강변역 - 잠실역 - 송파역 - 수서역 - 강남대역 - 명지대앞', '8443: 안산터미널 - 아주대역환승센터 - 강남대역 - 용인'],
                airportLimousine: ['8165: 김포공항 - 부천소풍터미널 - 보정역 - 강남대역 - 용인공용터미널입구',
                    '8852: 인천국제공항 - 보정역 - 보정역 - 강남대역 - 용인공용터미널출구'],
                parking: ['무료 주차 2시간']
            },
            { 
                subWay: ['분당선 [영통역] 하차 → 1번출구로 나옴 → 도보 10분 거리',],
                bus: ['7000, 1112, 5100, 1007-1, M5107', '3, 2-1, 5, 63, 51, 34, 4-1, 63-1, 13-1, 82-2, 34-1',
                    '[하행][영통역/롯데마트] 하차 → 오른쪽 느티나무골육교 이용 도로반대편으로 건너옴 → 육교에서 내려와서 왼쪽으로 50m 도보 → 평익빌딩 8층',
                    '[상행][영통역/영통공원] 하차 → 정류장 바로 앞에 있는 건물(ABC마트, 베스킨라빈스) 뒷편→ 평익빌딩 8층'],
                airportLimousine: [],
                parking: ['무료 주차 1시간']
            },
        ],
        trafficInfEn: [
            { 
                subWay: ['Giheung Station: 20 minutes on foot from Giheung Station on the Bundang Line', 'Gangnam University Station: Transfer from Giheung Station to the Everline, ride 1 stop to Gangnam University Station'],
                bus: ['5000, 5005: Seoul Station - Baek Hospital - Gangnam University Station - Myongji University Front Gate',
                        '5001, 5003: Gangnam Station - Yangjae Station - Gangnam University Station - Myongji University Front Gate',
                        '5600: Gangbyeon (Dongseoul Bus Terminal) Station - Jamsil Station - Songpa Station - Suseo Station - Gangnam University Station - Myongji University Front Gate',
                        '8443: Ansan Terminal - Ajou University Station Transfer Center - Gangnam University Station - Yongin'],
                airportLimousine: ['8165: Gimpo Airport - Bucheon Sopoong Terminal - Bojeong Station - Gangnam University Station - Yongin Terminal Entrance',
                                    '8852: Incheon International Airport - Bojeong Station - Bojeong Station - Gangnam University Station - Yongin Terminal Exit'],
                parking: ['Free 2 Hour Parking']
            },
            { 
                subWay: ['Exit at [Yeongtong Station] on Bundang Line → Exit through Gate 1 → 10 minutes on foot'],
                bus: ['7000, 1112, 5100, 1007-1, M5107',
                        '3, 2-1, 5, 63, 51, 34, 4-1, 63-1, 13-1, 82-2, 34-1',
                        '[Southbound] Exit at Yeongtong Station/Lotte Mart → Cross the right Zelkova Tree Valley (Neutinamugol) Bridge → Walk 50m to the left after descending from the bridge → 8th floor of Pyeongik Building',
                        '[Northbound] Exit at Yeongtong Station/Yeongtong Park → Approach the building right in front of the bus stop (ABC Mart, Baskin Robbins) → Walk behind the said building → 8th floor of Pyeongik Building'
                    ],
                airportLimousine: [],
                parking: ['Free 1 Hour Parking']
            },
        ],
        trafficInfoMobile: [
            { index: 0, mode: 'subway', how: '기흥역', way: '분당선 기흥역에서 도보 20분 거리' },
            { index: 0, mode: 'subway', how: '강남대역', way: '기흥역에서 에버라인으로 환승 1정거장 후 강남대역' },
            { index: 0, mode: 'bus', how: '5000: 5005', way: '서울역 - 백병원 - 강남대역 - 명지대앞' },
            { index: 0, mode: 'bus', how: '5001: 5003', way: '강남역 - 양재역 - 강남대역 - 명지대앞' },
            { index: 0, mode: 'bus', how: '5600', way: '강변역 - 잠실역 - 송파역 - 수서역 - 강남대역 - 명지대앞' },
            { index: 0, mode: 'bus', how: '8443', way: '안산터미널 - 아주대역환승센터 - 강남대역 - 용인' },
            { index: 0, mode: 'airportLimousine', how: '8165', way: '김포공항 - 부천소풍터미널 - 보정역 - 강남대역 - 용인공용터미널입구' },
            { index: 0, mode: 'airportLimousine', how: '8852', way: '인천국제공항 - 보정역 - 보정역 - 강남대역 - 용인공용터미널출구' },
            { index: 1, mode: 'subway', how: '영통역', way: '분당선 [영통역] 하차 → 1번출구로 나옴 → 도보 10분 거리' },
            { index: 1, mode: 'bus', how: '7000: 1112: 5100: 1007-1: M5107', way: '[영통역/롯데마트] 하차 → 오른쪽 느티나무골육교 이용 도로반대편으로 건너옴 → 육교에서 내려와서 왼쪽으로 50m 도보 → 평익빌딩 8층' },
            { index: 1, mode: 'bus', how: '2-1, 13-1: 3, 63, 4-1: 63-1, 5: 51, 34-1: 34, 82-2', way: '[영통역/영통공원] 하차 → 정류장 바로 앞에 있는 건물(ABC마트, 베스킨라빈스) 뒷편→ 평익빌딩 8층' },
        ],
    }),
};
</script>

<style>
    .txtColr { color: rgba(0, 0, 0, 0.54); }
</style>