<template>
    <v-container class="paInit" fluid>
        <v-row class="pa-0" v-if="$vuetify.breakpoint.mobile == false" no-gutters>
            <v-col cols="12">
                <div class="subTitleBox d-inline-block d-flex align-center" style="position: relative;">
                    <span class="decoPrllg d-inline-block subDeco1"></span>
                    <span class="decoCircle d-inline-block subDeco1"></span>
                   
                    <span class="decoPrll2 d-inline-block subDeco2" style="right: 410px;"></span>
                   
                    <span class="decoRgBar rounded-pill rounded-l-0 d-inline-block white"></span>
                    <div>
                        <span class="font-weight-bold menuTxrColor--text mb-4"
                            style="margin-left: 120px; font-size: 2.2rem !important; display: inherit;">
                            <span>{{ $t('subTtl4') }}</span>
                        </span>
                        <span class="font-weight-thin menuTxrColor--text"
                            style="margin-left: 120px; font-size: 1.1rem !important; display: inherit;">
                            <span>{{ $t('csr') }}</span>
                        </span>
                    </div>
                    <v-spacer></v-spacer>
                    <span class="d-inline-block csrDecoImage"></span>
                </div>
            </v-col>
        </v-row>
        <v-row class="pa-0" v-else-if="$vuetify.breakpoint.mobile == true" no-gutters>
            <v-col cols="12">
                <div class="csrTitleBoxMobile" style="margin-bottom: 1px;"></div>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <SclCntrDetail v-if="$vuetify.breakpoint.mobile == false"></SclCntrDetail>
        <SclCntrDetailMobile v-else-if="$vuetify.breakpoint.mobile == true"></SclCntrDetailMobile>
    </v-container>
</template>

<script>
import SclCntrDetail from './SclCntrDetail.vue';
import SclCntrDetailMobile from './SclCntrDetailMobile.vue';

export default {
    name: 'SclCntrCnfcr',
        
    components: {
        SclCntrDetail,
        SclCntrDetailMobile,
    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{

    },
        
    methods: {

    },
    
    data: () => ({

    }),
};
</script>

<style>
    .csrTitleBoxMobile { 
        width: 100%; height: 200px; opacity: 0.8;
        background-image: url('../../assets/sub/children-of-uganda_260.jpg'); background-size: cover;
    }
    .csrDecoImagePrt { filter: drop-shadow(0 0 2rem red); }
    .csrDecoImage {
        width: 515px; height: 260px;
        background: linear-gradient(106deg, rgba(255,255,255,1) 1%, rgba(254,244,234,0) 50%, rgba(255,255,255,1) 99%)
        , url('../../assets/sub/children-of-uganda_260.jpg');
        background-size: cover;
    }
    .csrContent { clip-path: circle(50% at 50% 50%); }
    .csrCtnImage { width: 200px; height: 200px; background-size: 200px; background-image: url('../../assets/sub/csr_stc.png'); }
    .csrCtnImage2 { width: 200px; height: 200px; background-size: 200px; background-image: url('../../assets/sub/csr_unhcr.png'); background-position: center; }
    .csrCtnImage3 { width: 200px; height: 200px; background-size: 200px; background-image: url('../../assets/sub/goodsNaver.png'); background-position: center; }
</style>