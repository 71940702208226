import Vue from 'vue';
import VueRouter from 'vue-router';

import StartApp from '../components/StartApp.vue'
import NotFound from '../components/layout/NotFound.vue'

import CmpCnfcr from '../components/intro/CmpCnfcr.vue'
import CmpInt from '../components/intro/CmpInt.vue'
import CmpHstr from '../components/intro/CmpHstr.vue'
import AflFrm from '../components/intro/AflFrm.vue'
import CmpWayCome from '../components/intro/CmpWayCome.vue'
import ServicePolicies from '../components/intro/ServicePolicies.vue'
import PrvcPlcy from '../components/intro/PrvcPlcy.vue'

import SclCntrCnfcr from '../components/sclCntrb/SclCntrCnfcr.vue'

import ITCnfcr from '../components/ITDv/ITCnfcr.vue'
import ITPrg from '../components/ITDv/ITPrg.vue'
import EtERP from '../components/ITDv/EtERP.vue'

import BizCnfgr from '../components/silver/BizCnfgr.vue'
import NrsnHomeDvlp from '../components/silver/NrsnHomeDvlp.vue'
import SlvrBsns from '../components/silver/SlvrBsns.vue'

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => {
		if (err.name !== 'NavigationDuplicated') throw err;
	});
};

// Vue Router를 관리하는 객체
export const router = new VueRouter({
    mode: 'history',

    scrollBehavior() {
        return { x: 0, y: 0 }
    },

    routes: [
        {
            path: '*',
            component: NotFound
        },
        {
            // path: url 경로, component: 표시될 컴포넌트
            path: '/harryfood',
            name: 'HarryFood',
            beforeEnter() {
                window.location.href = 'https://food.harrygnd.co.kr'
            },
        },
        {
            path: '/',
            name: 'App',
            component: StartApp,
            props: true,
        },
        {
            path: '/cmp',
            name: 'CmpCnfcr',
            component: CmpCnfcr,
            props: true,
            children: [
                {
                    path: '/cmp/cmpInt',
                    name: 'CmpInt',
                    component: CmpInt,
                    props: true,
                },
                {
                    path: '/cmp/cmpHstr',
                    name: 'CmpHstr',
                    component: CmpHstr,
                    props: true,
                },
                {
                    path: '/cmp/aflFrm',
                    name: 'AflFrm',
                    component: AflFrm,
                    props: true,
                },
                {
                    path: '/cmp/cmpWayCome',
                    name: 'CmpWayCome',
                    component: CmpWayCome,
                    props: true,
                },
                {
                    path: '/cmp/policies',
                    name: 'ServicePolicies',
                    component: ServicePolicies,
                    props: true,
                },
                {
                    path: '/cmp/prvcPlcy',
                    name: 'PrvcPlcy',
                    component: PrvcPlcy,
                    props: true,
                },
            ],
        },
        {
            path: '/sclCntr',
            name: 'SclCntrCnfcr',
            component: SclCntrCnfcr,
            props: true,
        },
        {
            path: '/IT',
            name: 'ITCnfcr',
            component: ITCnfcr,
            props: true,
            children: [
                {
                    path: '/IT/ITPrg',
                    name: 'ITPrg',
                    component: ITPrg,
                    props: true,
                },
                {
                    path: '/IT/EtERP',
                    name: 'EtERP',
                    component: EtERP,
                    props: true,
                },
            ],
        },
        {
            path: '/biz',
            name: 'BizCnfgr',
            component: BizCnfgr,
            props: true,
            children: [
                {
                    path: '/biz/nrsnHomeDvlp',
                    name: 'NrsnHomeDvlp',
                    component: NrsnHomeDvlp,
                    props: true,
                },
                {
                    path: '/biz/bsns',
                    name: 'SlvrBsns',
                    component: SlvrBsns,
                    props: true,
                },
            ],
        },
    ]
})