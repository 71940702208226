<template>
    <v-container class="paInit" fluid>
        <v-row class="ma-0 pa-0" v-if="$vuetify.breakpoint.mobile == false" no-gutters>
            <v-col cols="12">
                <div class="subTitleBox d-inline-block d-flex align-center" style="position: relative;">
                    <span class="decoPrllg d-inline-block subDeco1"></span>
                    <span class="decoCircle d-inline-block subDeco1"></span>
                    <span class="decoPrll2 d-inline-block subDeco2"></span>
                    <span class="decoRgBar rounded-pill rounded-l-0 d-inline-block white"></span>
                    <div>
                        <span class="font-weight-bold menuTxrColor--text mb-4"
                            style="margin-left: 120px; font-size: 2.2rem !important; display: inherit;">
                            <span v-if="$parent.$parent.$parent.routerRocation == 'ITPrg'">{{ $t('subTtl1') }}</span>
                        </span>
                        <span class="font-weight-thin menuTxrColor--text"
                            style="margin-left: 120px; font-size: 1.1rem !important; display: inherit;">
                            {{ $t('prgmNm') }}
                            <span v-if="$parent.$parent.$parent.routerRocation == 'ITPrg'">{{ $t('itDvlp') }}</span>
                        </span>
                    </div>
                    <v-spacer></v-spacer>
                    <span class="d-inline-block ITDecoImage"></span>
                </div>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" v-else no-gutters>
            <v-col cols="12">
                <div class="itTitleBoxMobile" style="margin-bottom: 1px;">
                </div>
                <v-divider></v-divider>
                <v-row class="ma-0 py-4">
                    <v-spacer></v-spacer>
                    <v-col cols="11">
                        <span class="d-inline-block mr-4" style="width: 5px; height: 24px; background-color: #f7ae22;"></span>
                        <span class="font-weight-bold menuTxrColor--text" style="font-size: 1.6rem !important;">요양원관리프로그램 소개</span>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">
            <v-spacer v-if="$vuetify.breakpoint.mobile"></v-spacer>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 12">
                <router-view class="mt-1"></router-view>
            </v-col>
            <v-spacer v-if="$vuetify.breakpoint.mobile"></v-spacer>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'ITCnfcr',
        
    components: {

    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {
            this.subTitleControll(this.$parent.$parent.$parent.routerRocation)
        })
    },

    computed: {
        getRouterInfo: function () {
            return this.$parent.$parent.$parent.routerRocation
        },
    },

    watch:{
        getRouterInfo: function (routerName) {
            this.subTitleControll(routerName)
        },
    },
        
    methods: {
        subTitleControll: function (routerName) {
            for (let index = 0; index < this.subTitle.length; index++) {
                this.subTitle.splice(index, 1, false)
            }
            switch (routerName) {
                case 'ITPrg':
                    this.subTitle.splice(0, 1, true)
                    break;
                case 'EtERP':
                    this.subTitle.splice(1, 1, true)
                    break;
                case 'ServicePolicies':
                    this.subTitle.splice(2, 1, true)
                    break;
                case 'PrvcPlcy':
                    this.subTitle.splice(3, 1, true)
                    break;
                default:
                    break;
            }
        },
    },
    
    data: () => ({
        subTitle: [false, false, false, false],
    }),
};
</script>

<style>
    .itTitleBoxMobile { 
        width: 100%; height: 200px; opacity: 0.8;
        background-image: url('../../assets/sub/student-ga4fd32428_1920.jpg'); background-size: cover;
    }
    .ITDecoImage {
        width: 467px; height: 100%;
        background: linear-gradient(106deg, rgba(255,255,255,1) 15%, rgba(254,244,234,0) 50%, rgba(255,255,255,1) 95%)
        , url('../../assets/sub/student-ga4fd32428_1920.jpg');
        background-size: cover;
    }
</style>