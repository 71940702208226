<template>
    <div class="px-4">
        <v-row class="ma-0 pa-0 my-5" no-gutters>
            <v-col class="" cols="12">
                <span class="font-weight-bold fontSizeOneDotEight pl-4" style="border-left: 10px solid #f7ae22;">기부를 통한 사회공헌</span>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 mt-12" no-gutters>
            <v-col class="" cols="12">
                <v-row class="ma-0 pa-0" no-gutters>
                    <v-col cols="12">
                        <div class="d-flex justify-center"> 
                            <span class="d-inline-block csrCtnImage"></span>
                        </div>
                        <div class="d-flex justify-center mt-2">
                            <span class="d-inline-block font-weight-bold fontSizeOneDotEight">Save the Children</span> 
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="d-flex justify-center"> 
                            <span class="d-inline-block csrCtnImage2"></span>
                        </div>
                        <div class="d-flex justify-center mt-2">
                            <span class="d-inline-block font-weight-bold fontSizeOneDotEight">UNHCR 유엔난민기구</span> 
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="d-flex justify-center"> 
                            <span class="d-inline-block csrCtnImage3"></span>
                        </div>
                        <div class="d-flex justify-center mt-2">
                            <span class="d-inline-block font-weight-bold fontSizeOneDotEight">굿네이버스</span> 
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 mt-10" no-gutters>
            <v-col class="d-flex justify-center align-center" cols="3">
                <div>
                    <div class="font-weight-bold" style="font-size: 1.4rem; text-align: center;">국내</div>
                    <div class="font-weight-bold" style="font-size: 1.4rem; text-align: center;">기부</div> 
                </div>
            </v-col>
            <v-col class="d-flex align-center pr-2" cols="9">
                <div class="font-weight-medium fontSizeOne">
                    매월 진행되는 저희의 조그만 약속으로 인해 국내 어려운 형편의 아이들이 더 건강하고 밝게 자라나기를 기원합니다.
                </div> 
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 mt-4" no-gutters>
            <v-col class="d-flex justify-center align-center" cols="3">
                <div>
                    <div class="font-weight-bold" style="font-size: 1.4rem; text-align: center;">해외</div>
                    <div class="font-weight-bold" style="font-size: 1.4rem; text-align: center;">기부</div> 
                </div>
            </v-col>
            <v-col class="d-flex align-center pr-2" cols="9">
                <div class="font-weight-medium fontSizeOne">
                    작은 정성으로 난민들에게 희망을 주고, 보건의료 취약지역에 좋은 변화 이야기를 만들어 갑니다.
                </div> 
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 my-10" no-gutters>
            <v-col class="" cols="12">
                <span class="font-weight-bold fontSizeOneDotEight pl-4" style="border-left: 10px solid #f7ae22;">봉사활동</span>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 mt-2" no-gutters>
            <v-col class="mb-6 pr-1" cols="12">
                <v-img class="rounded-xl" src="../../assets/sub/birthdayParty1.png" alt="요양원 생일파티1" :aspect-ratio="16/9"></v-img>
            </v-col>
            <v-col class="pl-1" cols="12">
                <v-img class="rounded-xl" src="../../assets/sub/birthdayParty2.png" alt="요양원 생일파티2" :aspect-ratio="16/9"></v-img>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 my-10" no-gutters>
            <v-col class="d-flex justify-center align-center" cols="3">
                <div>
                    <div class="font-weight-bold" style="font-size: 1.4rem; text-align: center;">봉사</div>
                    <div class="font-weight-bold" style="font-size: 1.4rem; text-align: center;">기부</div> 
                </div>
            </v-col>
            <v-col class="d-flex align-center pr-2" cols="9">
                <div class="font-weight-medium fontSizeOne">
                    거동이 불편한 어르신들의 병원이송서비스, 말동무하기, 음식수발 등 가까운 가족처럼 따뜻하게 살아갑니다.
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'SclCntrDetailMobile',
        
    components: {

    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{

    },
        
    methods: {

    },
    
    data: () => ({

    }),
};
</script>