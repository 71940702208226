<template>
    <v-container class="paInit" fluid>
        <v-row class="ma-0 pa-0" v-if="$vuetify.breakpoint.mobile == false" no-gutters>
            <v-col cols="12">
                <div class="subTitleBox d-inline-block d-flex align-center" style="position: relative;">
                    <span class="decoPrllg d-inline-block subDeco1"></span>
                    <span class="decoCircle d-inline-block subDeco1"></span>
                    <span class="decoPrll2 d-inline-block subDeco2"></span>
                    <span class="decoRgBar rounded-pill rounded-l-0 d-inline-block white"></span>
                    <div>
                        <span class="font-weight-bold menuTxrColor--text mb-4"
                            style="margin-left: 120px; font-size: 2.2rem !important; display: inherit;">
                            <span v-if="$parent.$parent.$parent.routerRocation == 'CmpInt'">{{ $t('subTtl5') }}</span>
                            <span v-else-if="$parent.$parent.$parent.routerRocation == 'CmpHstr'">{{ $t('subTtl6') }}</span>
                            <span v-else-if="$parent.$parent.$parent.routerRocation == 'AflFrm'">{{ $t('subTtl7') }}</span>
                            <span v-else-if="$parent.$parent.$parent.routerRocation == 'CmpWayCome'">{{ $t('subTtl8') }}</span>
                            <span v-else-if="$parent.$parent.$parent.routerRocation == 'ServicePolicies'">{{ $t('subTtl9') }}</span>
                            <span v-else-if="$parent.$parent.$parent.routerRocation == 'PrvcPlcy'">{{ $t('subTtl10') }}</span>
                        </span>
                        <span class="font-weight-thin menuTxrColor--text"
                            style="margin-left: 120px; font-size: 1.1rem !important; display: inherit;">
                            {{ $t('cmpCorp') }}
                            <span v-if="$parent.$parent.$parent.routerRocation == 'CmpInt'">{{ $t('cmpSubMenu1') }}</span>
                            <span v-else-if="$parent.$parent.$parent.routerRocation == 'CmpHstr'">{{ $t('cmpSubMenu2') }}</span>
                            <span v-else-if="$parent.$parent.$parent.routerRocation == 'AflFrm'">{{ $t('cmpSubMenu3') }}</span>
                            <span v-else-if="$parent.$parent.$parent.routerRocation == 'CmpWayCome'">{{ $t('cmpSubMenu4') }}</span>
                        </span>
                    </div>
                    <v-spacer></v-spacer>
                    <span class="d-inline-block subDecoImage"></span>
                </div>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" v-else-if="$vuetify.breakpoint.mobile == true" no-gutters>
            <v-col cols="12">
                <div class="subTitleBoxMobile" style="margin-bottom: 1px;">
                    
                </div>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-0 pa-0" v-if="$vuetify.breakpoint.mobile == false" no-gutters>
            <v-col cols="12">
                <div class="d-flex justify-center" v-if="$parent.$parent.$parent.routerRocation != 'ServicePolicies' && $parent.$parent.$parent.routerRocation != 'PrvcPlcy'">
                    <v-btn height="65"
                        :class="['sub-button fontSizeOneDotTwo rounded-0 elmBl elmBr px-8', subTitle[0] == true ? 'pointColor white--text' : '']"
                        :ripple="false" @click="$parent.$parent.$parent.$refs.appHeader.routerAction('CmpInt')" text>{{ $t('footMenu1') }}</v-btn>
                    <v-btn height="65"
                        :class="['sub-button fontSizeOneDotTwo rounded-0 elmBr px-8', subTitle[1] == true ? 'pointColor white--text' : '']"
                        :ripple="false" @click="$parent.$parent.$parent.$refs.appHeader.routerAction('CmpHstr')" text>{{ $t('cmpSubMenu2') }}</v-btn>
                    <v-btn height="65"
                        :class="['sub-button fontSizeOneDotTwo rounded-0 elmBr px-8', subTitle[2] == true ? 'pointColor white--text' : '']"
                        :ripple="false" @click="$parent.$parent.$parent.$refs.appHeader.routerAction('AflFrm')" text>{{ $t('cmpSubMenu3') }}</v-btn>
                    <v-btn height="65"
                        :class="['sub-button fontSizeOneDotTwo rounded-0 elmBr px-8', subTitle[3] == true ? 'pointColor white--text' : '']"
                        :ripple="false" @click="$parent.$parent.$parent.$refs.appHeader.routerAction('CmpWayCome')" text>{{ $t('cmpSubMenu4') }}</v-btn>
                </div>
                <div class="d-flex justify-center" v-else>
                    <v-btn height="65"
                        :class="['sub-button fontSizeOneDotTwo rounded-0 elmBl elmBr px-8', subTitle[4] == true ? 'pointColor white--text' : '']"
                        :ripple="false" @click="$parent.$parent.$parent.$refs.appHeader.routerAction('ServicePolicies')" text>
                        {{ $t('subTtl9') }}
                    </v-btn>
                    <v-btn height="65"
                        :class="['sub-button fontSizeOneDotTwo rounded-0 elmBr px-8', subTitle[5] == true ? 'pointColor white--text' : '']"
                        :ripple="false" @click="$parent.$parent.$parent.$refs.appHeader.routerAction('PrvcPlcy')" text>
                        {{ $t('subTtl10') }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="font-weight-bold menuTxrColor--text fontSizeOneDotEight pl-7 my-4"
            v-if="$vuetify.breakpoint.mobile == true">
            <span class="pl-4" style="border-left: 5px solid #f7ae22;"></span>
            <span v-if="$parent.$parent.$parent.routerRocation == 'CmpInt'">회사소개</span>
            <span v-else-if="$parent.$parent.$parent.routerRocation == 'CmpHstr'">회사연혁</span>
            <span v-else-if="$parent.$parent.$parent.routerRocation == 'AflFrm'">관계사 현황</span>
            <span v-else-if="$parent.$parent.$parent.routerRocation == 'CmpWayCome'">오시는길</span>
            <span v-else-if="$parent.$parent.$parent.routerRocation == 'ServicePolicies'">이용약관</span>
            <span v-else-if="$parent.$parent.$parent.routerRocation == 'PrvcPlcy'">개인정보취급정책</span>
        </div>
        <v-divider v-if="$vuetify.breakpoint.mobile == true"></v-divider>
        <router-view class="mt-1"></router-view>
    </v-container>
</template>

<script>
export default {
    name: 'CmpCnfcr',
        
    components: {

    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {
            this.subTitleControll(this.$parent.$parent.$parent.routerRocation)
        })
    },

    computed: {
        getRouterInfo: function () {
            return this.$parent.$parent.$parent.routerRocation
        },
    },

    watch:{
        getRouterInfo: function (routerName) {
            this.subTitleControll(routerName)
        },
    },
        
    methods: {
        subTitleControll: function (routerName) {
            for (let index = 0; index < this.subTitle.length; index++) {
                this.subTitle.splice(index, 1, false)
            }
            switch (routerName) {
                case 'CmpInt':
                    this.subTitle.splice(0, 1, true)
                    break;
                case 'CmpHstr':
                    this.subTitle.splice(1, 1, true)
                    break;
                case 'AflFrm':
                    this.subTitle.splice(2, 1, true)
                    break;
                case 'CmpWayCome':
                    this.subTitle.splice(3, 1, true)
                    break;
                case 'ServicePolicies':
                    this.subTitle.splice(4, 1, true)
                    break;
                case 'PrvcPlcy':
                    this.subTitle.splice(5, 1, true)
                    break;
                default:
                    break;
            }
        },
    },
    
    data: () => ({
        subTitle: [false, false, false, false, false, false],
    }),
};
</script>

<style>
    .subTitleBox { width: 100%; height: 260px; background-color: rgba(254,244,234,1); }
    .subTitleBoxMobile { 
        width: 100%; height: 200px; opacity: 0.8;
        background-image: url('../../assets/sub/hands-gbd.jpg'); background-size: cover;
    }
    .decoRgBar { 
        width: 200px; height: 50px; position: absolute; top: 4px; left: 20px; z-index: 2;
        -webkit-transform: rotate(90deg); transform: rotate(126deg); opacity: 0.5;
    }
    .decoPrllg {
        clip-path: polygon(75% 0, 100% 0, 25% 100%, 8% 88%);
        width: 150px; height: 160px; position: absolute; top: 0px; left: -30px; z-index: 1;
    }
    .decoPrll2 {
        clip-path: polygon(40% 0, 100% 0%, 60% 100%, 0 100%);
        width: 260px; height: 260px; position: absolute; top: 0px; right: 337px; z-index: 1; 
    }
    .decoCircle {
        clip-path: circle(50% at 50% 50%);
        width: 32px; height: 32px; position: absolute; top: 136px; left: -23px; z-index: 1;
    }
    .subDecoImage {
        width: 467px; height: 100%;
        background: linear-gradient(106deg, rgba(255,255,255,1) 15%, rgba(254,244,234,0) 50%, rgba(255,255,255,1) 95%)
        , url('../../assets/sub/hands-gbd.jpg');
        background-size: cover;
    }
    .subMenuBox { height: 65px; }
    .elmBl { border-left: 1px solid rgba(0, 0, 0, 0.12); }
    .elmBr { border-right: 1px solid rgba(0, 0, 0, 0.12); }
    .sub-button::before { display: none; }
</style>