<template>
    <v-app id="app">
        <!-- Header -->
        <CmHeader ref="appHeader"></CmHeader>
        <nav class="d-inline-block" style="width: 0px; height: 0px;">
            <ul>
                <li style="list-style: none;">
                    <a class="white--text" href="https://www.harrygnd.co.kr/cmp/cmpInt">회사소개</a>
                </li>
                <li style="list-style: none;">
                    <a class="white--text" href="https://food.harrygnd.co.kr">위탁급식</a>
                </li>
                <li style="list-style: none;">
                    <a class="white--text" href="https://www.harrygnd.co.kr/cmp/aflFrm">관계사현황</a>
                </li>
            </ul>
        </nav>

        <!-- app Menu -->
        <CmMobileDrawer ref="appDrawer"></CmMobileDrawer>

        <!-- main -->
        <v-main class="">
            <router-view></router-view>
        </v-main>

        <!-- footer -->
        <v-footer class="" color="bgColor2">
            <CmFooter v-if="$vuetify.breakpoint.mobile == false"></CmFooter>
            <CmFooterMobile v-else-if="$vuetify.breakpoint.mobile == true"></CmFooterMobile>
        </v-footer>
    </v-app>
</template>

<script>
import CmHeader from './components/layout/CmHeader.vue';
import CmMobileDrawer from './components/layout/CmMobileDrawer.vue';
import CmFooter from './components/layout/CmFooter.vue';
import CmFooterMobile from './components/layout/CmFooterMobile.vue';

require('./css/FontFamily.css')

export default {
    metaInfo: {
        title: '(주)해리지앤디',

        htmlAttrs: {
        // lang: window.getApp.$i18n.locale // i18n 사용하지 않는 경우 직접 입력
        },

        meta: [
            { charset: 'utf-8'},

            // SEO setting
            { name: 'description', content: '장기요양관리시스템, 위탁급식, 실버용품, 요양기관 토탈서비스', vmid: 'description'},
            { name: 'keywords', content: '해리지앤디, 장기요양관리시스템, 위탁급식, 실버용품, 요양원컨설팅'},
            { name: 'author', content: '(주)해리지앤디'},
            { name: 'robots', content: 'inindex,followdex'},

            // SNS 용
            { property: 'og:title', content: '(주)해리지앤디', vmid: 'og:title' },
            { property: 'og:type', vmid: 'og:type', content: 'website'},
            { property: 'og:description', vmid: 'og:description', content: '장기요양관리시스템, 위탁급식, 실버용품, 요양기관 토탈서비스'},
            { property: 'og:image', vmid: 'og:image', content: 'https://www.harrygnd.co.kr/favicon.ico'},
            { property: 'og:url', vmid: 'og:url', content: 'https://www.harrygnd.co.kr/'},

            // mobile
            { name: 'viewport', content: 'width=device-width, initial-scale=1'}
        ],
    },

    name: 'App',
        
    components: {
        CmHeader,
        CmMobileDrawer,
        CmFooter,
        CmFooterMobile,
    },

    created: function(){
        this.routerRocation = this.$route.name
    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{ 
        '$route' (to, from) {
            this.routerRocation = to.name
            this.name = from.name
        }
    },
        
    methods: {
        
    },
    
    data: () => ({
        routerRocation: '',
        name: '',
    }),
};
</script>

<style>
    .v-application { font-family: 'Noto Sans KR', sans-serif !important; font-size: 1.1rem !important;}
    .titleFontSize { font-size: 1.4rem !important; }
    .fontSizeThree { font-size: 3rem !important; }
    .fontSizeOneDotEight { font-size: 1.8rem !important; }
    .fontSizeOneDotTwo { font-size: 1.2rem !important; }
    .fontSizeOne { font-size: 1rem !important; }
    .fontSizeDotEight { font-size: 0.875rem !important; }
    .fontSizeDotSeven { font-size: 0.75rem !important; }
    .hideEle { display: none; }

    .txt_line {
      width: 296px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
</style>