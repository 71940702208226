<template>
    <v-app-bar class="fixed-bar" color="white" :height="$vuetify.breakpoint.mobile ? 80 : 120"
        @mouseover="onOverEvent()" @mouseleave="outOverEvent()" app>
        <v-spacer></v-spacer>
        <div class="d-flex justify-center align-center d-inline-block" style="width: 1200px; height: 100%;"
            v-if="$vuetify.breakpoint.mobile == false">
            <span class="d-inline-block d-flex align-center appBarSubMenu" style="width: 300px;" @click="routerAction('App')">
                <v-img
                    alt="해리지앤디 로고"
                    class="shrink mr-2" width="40"
                    src="../../assets/harrygndLogo.png"
                    transition="scale-transition" contain>
                </v-img>
                <span class="ml-4 font-weight-black fontSizeOneDotEight logoColor--text">{{ $t('cmpNm') }}</span>
            </span>
            <a :class="['d-inline-block d-flex justify-center align-center titleFontSize appBarSubMenu font-weight-black', hMenuList[0] == true ? 'pointColor--text' : 'menuTxrColor--text']"
                style="text-decoration: none;" @mouseover="hMenuControll(276, 185)" @click="routerAction('ITPrg')">
                {{ $t('headMenu1') }}
            </a>
            <a :class="['d-inline-block d-flex justify-center align-center titleFontSize appBarSubMenu font-weight-black', hMenuList[1] == true ? 'pointColor--text' : 'menuTxrColor--text']"
                style="text-decoration: none;" @mouseover="hMenuControll(462, 184)" href="https://food.harrygnd.co.kr/">
                {{ $t('headMenu2') }}
            </a>
            <a :class="['d-inline-block d-flex justify-center align-center titleFontSize appBarSubMenu font-weight-black', hMenuList[2] == true ? 'pointColor--text' : 'menuTxrColor--text']"
                style="text-decoration: none;" @mouseover="hMenuControll(646, 184)" @click="routerAction('NrsnHomeDvlp')">
                {{ $t('headMenu3') }}
            </a>
            <a :class="['d-inline-block d-flex justify-center align-center titleFontSize appBarSubMenu font-weight-black', hMenuList[3] == true ? 'pointColor--text' : 'menuTxrColor--text']"
                style="text-decoration: none;" @mouseover="hMenuControll(831, 184)" @click="routerAction('SclCntrCnfcr')">
                {{ $t('headMenu4') }}
            </a>
            <a :class="['d-inline-block d-flex justify-center align-center titleFontSize appBarSubMenu font-weight-black', hMenuList[4] == true ? 'pointColor--text' : 'menuTxrColor--text']"
                style="text-decoration: none;" @mouseover="hMenuControll(1015, 184)"  @click="routerAction('CmpInt')">
                {{ $t('headMenu5') }}
            </a>
        </div>
        <div class="d-flex justify-center align-center d-inline-block ml-1 mr-2" style="width: 100%;"
            v-else-if="$vuetify.breakpoint.mobile == true">
            <span class="d-inline-block d-inline-block d-flex align-center appBarSubMenu" style="width: 200px;" @click="routerAction('App')">
                <v-img
                    alt="해리지앤디 로고"
                    class="shrink mr-2" width="30"
                    src="../../assets/harrygndLogo.png"
                    transition="scale-transition" contain>
                </v-img>
                <span class="ml-1 font-weight-black titleFontSize logoColor--text">해리지앤디</span>
            </span>
            <v-spacer></v-spacer>
            <v-btn @click="$parent.$parent.$refs.appDrawer.drawer=true" icon>
                <v-icon size="33">mdi-menu</v-icon>
            </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-expand-transition>
            <div v-if="hMenu" class="d-flex justify-center exHeaderMenu elevation-4" style="border-top: 1px solid #dedede;">
                <div class="d-flex justify-center d-inline-block" style="width: 1200px; height: 100%; position: relative;">
                    <span class="d-inline-block hMenuSmallBar hMenuMovingBar" :style="trnStyle"></span>
                    <span class="d-inline-block" style="width: 300px;"></span>
                    <span class="exHeaderCol appBarSubMenu py-4" @mouseover="hMenuControll(276, 185)">
                    </span>
                    <span class="appBarSubMenu">
                    </span>
                    <span class="exHeaderCol appBarSubMenu py-4" @mouseover="hMenuControll(646, 184)">
                        <div class="exHeaderTxt font-weight-bold pa-1" @click="routerAction('NrsnHomeDvlp')">{{ $t('headM3Sub1') }}</div>
                        <div class="exHeaderTxt font-weight-bold pa-1" @click="routerAction('SlvrBsns')">{{ $t('headM3Sub2') }}</div>
                    </span>
                    <span class="appBarSubMenu">
                    </span>
                    <span class="exHeaderCol appBarSubMenu py-4" @mouseover="hMenuControll(1015, 184)">
                        <div class="exHeaderTxt font-weight-bold pa-1" @click="routerAction('CmpInt')">{{ $t('headM5Sub1') }}</div>
                        <div class="exHeaderTxt font-weight-bold pa-1" @click="routerAction('CmpHstr')">{{ $t('headM5Sub2') }}</div>
                        <div class="exHeaderTxt font-weight-bold pa-1" @click="routerAction('AflFrm')">{{ $t('headM5Sub3') }}</div>
                    </span>
                </div>
            </div>
        </v-expand-transition>
    </v-app-bar>
</template>

<script>
export default {
    name: 'CmHeader',
        
    components: {

    },
    
    props: {
      
    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {
           
        })
    },

    computed: {
        trnStyle () {
            return {
                '--trans-width': this.trnPosn + 'px',
                '--width': this.trnWidth + 'px',
            }
        },
    },

    watch:{ 

    },
        
    methods: {
        routerAction: function (path) {
            this.$router.push({ name: path, });
        },
        onOverEvent: function () {
            if(this.$vuetify.breakpoint.mobile == false)
                this.hMenu = true
        },
        outOverEvent: function () {
            if(this.$vuetify.breakpoint.mobile == false)
                this.hMenu = false

            for (let index = 0; index < this.hMenuList.length; index++) {
                this.hMenuList.splice(index, 1, false)
            }
        },
        hMenuControll: function (posn, width) {
            if(this.trnPosn != posn){
                this.trnPosn = posn
                this.trnWidth = width
                for (let index = 0; index < this.hMenuList.length; index++) {
                    this.hMenuList.splice(index, 1, false)
                }
                switch (this.trnPosn) {
                    case 276:
                        this.hMenuList.splice(0, 1, true)
                        break;
                    case 462:
                        this.hMenuList.splice(1, 1, true)
                        break;
                    case 646:
                        this.hMenuList.splice(2, 1, true)
                        break;
                    case 831:
                        this.hMenuList.splice(3, 1, true)
                        break;
                    case 1015:
                        this.hMenuList.splice(4, 1, true)
                        break;
                    default:
                        break;
                }
            }
        },
    },
    
    data: () => ({
        hMenu: false,
        hMenuList: [false, false, false, false, false, ],
        trnPosn: 0,
        trnWidth: 0,
    }),
};
</script>

<style>
    .fixed-bar {
        position: sticky; position: -webkit-sticky; top: 0; z-index: 2; opacity: 0.9;
    }
    .exHeaderMenu {
        position: absolute; top: 120px; left: 0; z-index: 1; width: 100%; height: 138px;
        background-color: white;
    }
    .appBarSubMenu { width: 200px; height: 100%; text-align: center; vertical-align: middle; cursor:pointer; }
    .exHeaderTxt:hover { color: #F7AE22 !important; }
    .exHeaderCol:hover{ background: linear-gradient( to bottom, rgba(242, 242, 242, 0.6), 80%, white ); }
    .hMenuSmallBar { position: absolute; height: 5px; top: -5px; left: 0; background-color: #F7AE22; transform: translateX( 0px ); }
    .hMenuMovingBar { width: var(--width); transform: translateX( var(--trans-width) ); transition: transform 0.2s; }
</style>