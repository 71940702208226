<template>
    <v-row class="ma-0 pa-0 overflow-x-hidden" no-gutters>
        <v-col class="d-flex justify-center mt-16" cols="12">
            <div :class="[$vuetify.breakpoint.mobile == false ? 'mt-10' : '']">
                <div class="palette">
                    <div class="color1"></div>
                    <div class="color2"></div>
                    <div class="color3"></div>
                </div>
                <div class="" style="position: relative;">
                    <span class="trianglePalet tri1"></span>
                    <span class="trianglePalet tri2"></span>
                    <span class="trianglePalet tri3"></span>
                    <span
                        class="doughnutTitle d-inline-block font-weight-bold fontSizeOneDotEight" style="text-align: center;">
                        {{ $t('cmpBsns') }}
                    </span>
                    <span v-if="$vuetify.breakpoint.mobile == false"
                        class="d-inline doughnutSubTitle1 titleFontSize font-weight-medium py-1"
                        style="border-bottom: 3px solid #42a1d9;">
                        {{ $t('cmpTtl3') }}
                    </span>
                    <span v-if="$vuetify.breakpoint.mobile == false"
                        class="d-inline doughnutSubTitle2 titleFontSize font-weight-medium py-1"
                        style="border-bottom: 3px solid #477992;">
                        {{ $t('cmpTtl2') }}
                    </span>
                    <span v-if="$vuetify.breakpoint.mobile == false"
                        class="d-inline doughnutSubTitle3 titleFontSize font-weight-medium py-1"
                        style="border-bottom: 3px solid #7cbeca;;">
                        {{ $t('cmpTtl1') }}
                    </span>
                    <v-icon class="doughnutSubicon1" color="white" size="50">mdi-desktop-classic</v-icon>
                    <v-icon class="doughnutSubicon2" color="white" size="50">mdi-shopping-outline</v-icon>
                    <v-icon class="doughnutSubicon3" color="white" size="50">mdi-rice</v-icon>
                </div>
            </div>
        </v-col>
        <v-col class="d-flex justify-center" v-if="!$vuetify.breakpoint.mobile" cols="12">
            <div class="mt-6" style="width: 50%;">
                <v-row class="mt-4 mb-6" no-gutters>
                    <v-col class="mb-4"
                        :cols="$vuetify.breakpoint.mobile ? 12 : 4">
                        <v-row class="ma-0 pa-0" no-gutters>
                            <v-col class="d-flex justify-center py-1" cols="2">
                                <v-divider class="" style="border-width: 2px; border-color: #7cbeca;" vertical></v-divider>
                            </v-col>
                            <v-col cols="10">
                                <span class="font-weight-bold titleFontSize">{{ $t('cmpTtl1') }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 mt-5" no-gutters>
                            <v-col class="" style="text-align: right;" cols="2">
                                <span class="">
                                    <v-icon class="" size="30">mdi-circle-small</v-icon>
                                </span>
                            </v-col>
                            <v-col cols="10">
                                <span class="fontSizeOneDotTwo">{{ $t('cmpSub1Cntnt1') }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 mt-1" no-gutters>
                            <v-col class="" style="text-align: right;" cols="2">
                                <span class="">
                                    <v-icon class="" size="30">mdi-circle-small</v-icon>
                                </span>
                            </v-col>
                            <v-col cols="10">
                                <span class="fontSizeOneDotTwo">{{ $t('cmpSub1Cntnt2') }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 mt-1" no-gutters>
                            <v-col class="" style="text-align: right;" cols="2">
                                <span class="">
                                    <v-icon class="" size="30">mdi-circle-small</v-icon>
                                </span>
                            </v-col>
                            <v-col cols="10">
                                <span class="fontSizeOneDotTwo">{{ $t('cmpSub1Cntnt3') }}</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="mb-4"
                        :cols="$vuetify.breakpoint.mobile == false ? 4 : 12">
                        <v-row class="ma-0 pa-0" no-gutters>
                            <v-col class="d-flex justify-center py-1" cols="2">
                                <v-divider class="" style="border-width: 2px; border-color: #7cbeca;" vertical></v-divider>
                            </v-col>
                            <v-col cols="10">
                                <span class="font-weight-bold titleFontSize">{{ $t('cmpTtl3') }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 mt-5" no-gutters>
                            <v-col class="" style="text-align: right;" cols="2">
                                <span class="">
                                    <v-icon class="" size="30">mdi-circle-small</v-icon>
                                </span>
                            </v-col>
                            <v-col cols="10">
                                <span class="fontSizeOneDotTwo">{{ $t('cmpSub2Cntnt1') }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 mt-1" no-gutters>
                            <v-col class="" style="text-align: right;" cols="2">
                                <span class="">
                                    <v-icon class="" size="30">mdi-circle-small</v-icon>
                                </span>
                            </v-col>
                            <v-col cols="10">
                                <span class="fontSizeOneDotTwo">{{ $t('cmpSub2Cntnt2') }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 mt-1" no-gutters>
                            <v-col class="" style="text-align: right;" cols="2">
                                <span class="">
                                    <v-icon class="" size="30">mdi-circle-small</v-icon>
                                </span>
                            </v-col>
                            <v-col cols="10">
                                <span class="fontSizeOneDotTwo">{{ $t('cmpSub2Cntnt3') }}</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="mb-4"
                        :cols="$vuetify.breakpoint.mobile == false ? 4 : 12">
                        <v-row class="ma-0 pa-0" no-gutters>
                            <v-col class="d-flex justify-center py-1" cols="2">
                                <v-divider class="" style="border-width: 2px; border-color: #7cbeca;" vertical></v-divider>
                            </v-col>
                            <v-col cols="10">
                                <span class="font-weight-bold titleFontSize">{{ $t('cmpTtl2') }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 mt-5" no-gutters>
                            <v-col class="" style="text-align: right;" cols="2">
                                <span class="">
                                    <v-icon class="" size="30">mdi-circle-small</v-icon>
                                </span>
                            </v-col>
                            <v-col cols="10">
                                <span class="fontSizeOneDotTwo">{{ $t('cmpSub3Cntnt1') }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 mt-1" no-gutters>
                            <v-col class="" style="text-align: right;" cols="2">
                                <span class="">
                                    <v-icon class="" size="30">mdi-circle-small</v-icon>
                                </span>
                            </v-col>
                            <v-col cols="10">
                                <span class="fontSizeOneDotTwo">{{ $t('cmpSub3Cntnt2') }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 mt-1" no-gutters>
                            <v-col class="" style="text-align: right;" cols="2">
                                <span class="">
                                    <v-icon class="" size="30">mdi-circle-small</v-icon>
                                </span>
                            </v-col>
                            <v-col cols="10">
                                <span class="fontSizeOneDotTwo">{{ $t('cmpSub3Cntnt3') }}</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <v-col class="d-flex justify-center mt-2" v-else cols="12">
            <v-row class="mt-4 mb-6" no-gutters>
                <v-col class="mb-4"
                    :cols="$vuetify.breakpoint.mobile ? 12 : 4">
                    <v-row class="ma-0 pa-0" no-gutters>
                        <v-col class="d-flex justify-center py-1" cols="2">
                            <v-divider class="" style="border-width: 2px; border-color: #7cbeca;" vertical></v-divider>
                        </v-col>
                        <v-col cols="10">
                            <span class="font-weight-bold titleFontSize">IT 개발</span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 mt-3" no-gutters>
                        <v-col class="" style="text-align: right;" cols="3">
                            <span class="">
                                <v-icon class="" size="30">mdi-circle-small</v-icon>
                            </span>
                        </v-col>
                        <v-col cols="9">
                            <span class="fontSizeOneDotTwo">요양기관 관리시스템 ASP</span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="" style="text-align: right;" cols="3">
                            <span class="">
                                <v-icon class="" size="30">mdi-circle-small</v-icon>
                            </span>
                        </v-col>
                        <v-col cols="9">
                            <span class="fontSizeOneDotTwo">홈페이지 서비스</span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="" style="text-align: right;" cols="3">
                            <span class="">
                                <v-icon class="" size="30">mdi-circle-small</v-icon>
                            </span>
                        </v-col>
                        <v-col cols="9">
                            <span class="fontSizeOneDotTwo">ERP 구축</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="mb-4"
                    :cols="$vuetify.breakpoint.mobile == false ? 4 : 12">
                    <v-row class="ma-0 pa-0" no-gutters>
                        <v-col class="d-flex justify-center py-1" cols="2">
                            <v-divider class="" style="border-width: 2px; border-color: #7cbeca;" vertical></v-divider>
                        </v-col>
                        <v-col cols="10">
                            <span class="font-weight-bold titleFontSize">위탁급식</span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 mt-3" no-gutters>
                        <v-col class="" style="text-align: right;" cols="3">
                            <span class="">
                                <v-icon class="" size="30">mdi-circle-small</v-icon>
                            </span>
                        </v-col>
                        <v-col cols="9">
                            <span class="fontSizeOneDotTwo">요양기관 위탁급식</span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="" style="text-align: right;" cols="3">
                            <span class="">
                                <v-icon class="" size="30">mdi-circle-small</v-icon>
                            </span>
                        </v-col>
                        <v-col cols="9">
                            <span class="fontSizeOneDotTwo">회사/산업체 급식</span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="" style="text-align: right;" cols="3">
                            <span class="">
                                <v-icon class="" size="30">mdi-circle-small</v-icon>
                            </span>
                        </v-col>
                        <v-col cols="9">
                            <span class="fontSizeOneDotTwo">병원 위탁급식</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="mb-4"
                    :cols="$vuetify.breakpoint.mobile == false ? 4 : 12">
                    <v-row class="ma-0 pa-0" no-gutters>
                        <v-col class="d-flex justify-center py-1" cols="2">
                            <v-divider class="" style="border-width: 2px; border-color: #7cbeca;" vertical></v-divider>
                        </v-col>
                        <v-col cols="10">
                            <span class="font-weight-bold titleFontSize">e-Commerce</span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 mt-3" no-gutters>
                        <v-col class="" style="text-align: right;" cols="3">
                            <span class="">
                                <v-icon class="" size="30">mdi-circle-small</v-icon>
                            </span>
                        </v-col>
                        <v-col cols="9">
                            <span class="fontSizeOneDotTwo">복지용구</span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="" style="text-align: right;" cols="3">
                            <span class="">
                                <v-icon class="" size="30">mdi-circle-small</v-icon>
                            </span>
                        </v-col>
                        <v-col cols="9">
                            <span class="fontSizeOneDotTwo">위생/의료 용품</span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="" style="text-align: right;" cols="3">
                            <span class="">
                                <v-icon class="" size="30">mdi-circle-small</v-icon>
                            </span>
                        </v-col>
                        <v-col cols="9">
                            <span class="fontSizeOneDotTwo">실버용품</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'CmpInt',
        
    components: {

    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        
    },
    
    data: () => ({

    }),
};
</script>

<style>
    .palette {
        --g:10px;
        --s:100px;

        height: 360px;
        width: 360px;
        position:relative;
        display:inline-block;
        overflow:hidden;
    }
    .palette > * {
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        border:var(--s) solid var(--c,#477992);
        transform:rotate(60deg);
        border-radius:50%;
        clip-path:polygon(
            calc(50% + var(--g)/2) 50%, 
            calc(50% + var(--g)/2) 0%, 
            100% 0%,
            100% calc(78.665% - var(--g)/2),
            50% calc(50% - var(--g)/2)); 
        }
    .color1 {
        transform:rotate(180deg);
        --c:#42a1d9;
    }
    .color2 {
        transform:rotate(-60deg);
        --c:#7cbeca;
    }
    .trianglePalet {
        width: 0;
        height: 0;
        position:absolute;
        z-index: 1;
        border-bottom: 12px solid transparent;
        border-top: 12px solid transparent;
        border-right: 20px solid transparent;
    }
    .tri1 { transform: rotate(149deg); top: -96px; left: -11px; border-left: 20px solid #42a1d9;}
    .tri2 { transform: rotate(30deg); top: -96px; left: 331px; border-left: 20px solid #477992;}
    .tri3 { transform: rotate(-90deg); top: -396px; left: 160px; border-left: 20px solid #7cbeca;}
    .doughnutTitle { position: absolute; z-index: 1; top: -208px; left: 0x; width: 100%; }

    .doughnutSubTitle1 { position: absolute; z-index: 1; top: -70px; left: -90px; }
    .doughnutSubTitle2 { min-width: 84px; position: absolute; z-index: 1; top: -70px; left: 350px; }
    .doughnutSubTitle3 { position: absolute; z-index: 1; top: -444px; left: 50%; transform: translateX(-50%); }
    .doughnutSubicon1 { position: absolute; z-index: 1; top: -340px; left: 156px; }
    .doughnutSubicon2 { position: absolute; z-index: 1; top: -148px; left: 208px; }
    .doughnutSubicon3 { position: absolute; z-index: 1; top: -148px; left: -50px; }
</style>