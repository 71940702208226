<template>
    <v-row class="ma-0 pa-0 py-10" no-gutters>
        <v-spacer></v-spacer>
        <v-col :cols="$vuetify.breakpoint.mobile == false ? 5 : 10">
            <v-timeline>
                <v-timeline-item
                    v-for="(year, i) in years" :key="i"
                    :color="year.color" small>
                    <template v-slot:opposite>
                        <span
                            :class="`headline font-weight-bold ${year.color}--text`"
                            v-text="year.year">
                        </span>
                    </template>
                    <v-card class="elevation-2">
                        <v-card-title
                            :class="['px-3 pt-3 pb-1', $vuetify.breakpoint.mobile == false ? 'fontSizeOne' : 'fontSizeDotEight']"
                            style="padding: initial; line-height: 1.4;">
                            {{ year.title }}
                        </v-card-title>
                        <v-card-text
                            :class="['font-weight-bold px-3 pb-3', $vuetify.breakpoint.mobile == false ? 'fontSizeDotEight' : 'fontSizeDotSeven']"
                            style="padding: initial;">
                            {{ year.text }}
                        </v-card-text>
                    </v-card>
                </v-timeline-item>
            </v-timeline>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
</template>

<script>
export default {
    name: 'CmpHstr',
        
    components: {

    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.$i18n.locale == 'en')
                this.years = Object.assign([], this.tmLnEn)
            else
                this.years = Object.assign([], this.tmLnKo)
        })
    },

    computed: {
        getLocale: function () {
            return this.$i18n.locale
        },
    },

    watch:{ 
        getLocale: function (v) {
            this.years.splice(0)
            if(v == 'en')
                this.years = Object.assign([], this.tmLnEn)
            else
                this.years = Object.assign([], this.tmLnKo)
        },
    },
        
    methods: {
        
    },
    
    data: () => ({
        years: [],
        tmLnKo: [
            { color: 'pointColor', year: '2024.7', title: '요양원 3호점 개원', text: '연 면적 660평 규모 요양원 24년 7월 개원' },
            { color: 'cyan', year: '2023.11', title: '벤처기업확인서 발급', text: '벤처기업확인서 발급 - 혁신성장유형' },
            { color: 'pointColor', year: '2022.10', title: '연구개발전담부서 인정', text: '연구개발전담부서 인정서 발급' },
            { color: 'cyan', year: '2021', title: '연구개발전담부서 설립', text: '연구개발전담부서 설립' },
            { color: 'pointColor', year: '2021.11', title: '서브원 SSP 프로젝트 수주', text: '서브원 MRO B to B 및 e-커머스 프로젝트 수주' },
            { color: 'cyan', year: '2021.11', title: 'ASP 프로그램 개발 착수', text: '장기요양기관 운영 ASP 프로그램 자체 개발 시작' },
            { color: 'pointColor', year: '2021.10', title: '요양원 집단급식 시작', text: '요양원 2곳 집단급식 개시' },
            { color: 'cyan', year: '2021.09', title: '삼성카드 프로젝트 수주', text: '아시아나 IDT와 삼성카드 홈페이지 프로젝트 수주' },
            { color: 'pointColor', year: '2021.08', title: '요양원 3호점 건축허가', text: '연면적 700평 규모 요양원 건축허가, 23년09월 완공예정' },
            { color: 'cyan', year: '2021.07', title: '직원 파견', text: 'IBK 연금보험 전산실 유지보수 인력 파견' },
            { color: 'pointColor', year: '2020.10', title: '요양원 2호점 개설', text: '영통 마더링 요양원 오픈' },
            { color: 'cyan', year: '2020', title: '실버산업의 시작', text: '요양원 인테리어 사업 매출 5억 달성' },
            { color: 'pointColor', year: '2020.03', title: '주식회사 해리지앤디 창립', text: 'IT 및 실버 산업 관련 요구에 부응하고자 설립' },
            { color: 'cyan', year: '2018', title: '실버산업의 시작', text: '용인소재 마더링케어요양원 인수' },
        ],
        tmLnEn: [
            { color: 'pointColor', year: '2023', title: 'Construction Permit Acquired and Construction in Progress for Nursing Home Branch #3', text: 'Planned completion of a 2,314 square meter nursing home by December 2023, with licensing scheduled for January 2024' },
            { color: 'cyan', year: '2023.11', title: 'Venture Enterprise Certificate Issued', text: 'Venture Enterprise Certificate Issued - Innovative Growth Category' },
            { color: 'pointColor', year: '2022.10', title: 'Research and Development Department Officially Recognized', text: 'Research and Development Department Recognition Certificate Issued' },
            { color: 'cyan', year: '2021', title: 'Research and Development Department Established', text: 'Research and Development Department Established' },
            { color: 'pointColor', year: '2021.11', title: 'Project Contract from SubOne SSP Secured', text: 'Awarded Project Contract from SubOne for MRO B to B and E-Commerce Projects' },
            { color: 'cyan', year: '2021.11', title: 'ASP Program Development Initiated', text: 'Started In-house Development of the ASP program for Long-term Care Facilities' },
            { color: 'pointColor', year: '2021.10', title: 'Nursing Home Meal Services Launched', text: 'Commenced of Meal Services at Two Nursing Homes' },
            { color: 'cyan', year: '2021.09', title: 'Project Contract from Samsung Card Secured', text: 'Awarded Project Contract from Asiana IDT and Samsung Card for Website Projects' },
            { color: 'pointColor', year: '2021.08', title: 'Construction Permit for the Nursing Home Branch #3 Authorized', text: 'Secured Construction Permit for a 2,314 square meter nursing home, scheduled for completion in December 2023' },
            { color: 'cyan', year: '2021.07', title: 'Personnel Deployment', text: 'Dispatched Employees for the Maintenance of IBK Pension Insurance Data Center' },
            { color: 'pointColor', year: '2020.10', title: 'Nursing Home Branch #2 Opened', text: 'Yeongtong Mothering Nursing Home Officially Opened' },
            { color: 'cyan', year: '2020', title: 'Start of the Senior Care Business Segment', text: 'Achieved 500 Million Won in Sales for Nursing Home Interior Design Business' },
            { color: 'pointColor', year: '2020.03', title: 'HarryGnD Co., Ltd. Established', text: 'Established to Meet the Growing Demands of IT and the Senior Care Industry' },
            { color: 'cyan', year: '2018', title: 'Start of the Senior Care Business Segment', text: 'Acquired of Yongin-based Mothering Care Nursing Home' }
        ]

    }),
};
</script>