<template>
    <div>
        <v-row class="ma-0 pa-0 my-5" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="" cols="8">
                <span class="font-weight-bold pl-4" style="font-size: 2rem; border-left: 10px solid #f7ae22;">{{ $t('sclTtl1') }}</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="ma-0 pa-0 mt-12" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="" cols="8">
                <v-row class="ma-0 pa-0" no-gutters>
                    <v-col cols="4">
                        <div class="d-flex justify-center"> 
                            <span class="d-inline-block csrCtnImage"></span>
                        </div>
                        <div class="d-flex justify-center mt-2">
                            <span class="d-inline-block font-weight-bold fontSizeOneDotEight">{{ $t('sclImgCntnt1') }}</span> 
                        </div>
                    </v-col>
                    <v-col cols="4">
                        <div class="d-flex justify-center"> 
                            <span class="d-inline-block csrCtnImage2"></span>
                        </div>
                        <div class="d-flex justify-center mt-2">
                            <span class="d-inline-block font-weight-bold fontSizeOneDotEight">{{ $t('sclImgCntnt2') }}</span> 
                        </div>
                    </v-col>
                    <v-col cols="4">
                        <div class="d-flex justify-center"> 
                            <span class="d-inline-block csrCtnImage3"></span>
                        </div>
                        <div class="d-flex justify-center mt-2">
                            <span class="d-inline-block font-weight-bold fontSizeOneDotEight">{{ $t('sclImgCntnt3') }}</span> 
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="ma-0 pa-0 mt-10" no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="2">
                <div class="font-weight-bold" style="font-size: 1.4rem; text-align: center;">{{ $t('sclSubTtl1') }}</div>
            </v-col>
            <v-col class="d-flex align-center" cols="6">
                <div class="font-weight-bold">
                    {{ $t('sclSubCntnt1') }}
                </div> 
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="ma-0 pa-0 mt-2" no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="2">
                <div class="font-weight-bold" style="font-size: 1.4rem; text-align: center;">{{ $t('sclSubTtl2') }}</div> 
            </v-col>
            <v-col class="d-flex align-center" cols="6">
                <div class="font-weight-bold">
                    {{ $t('sclSubCntnt2') }}
                </div> 
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="ma-0 pa-0 my-10" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="" cols="8">
                <span class="font-weight-bold pl-4" style="font-size: 2rem; border-left: 10px solid #f7ae22;">{{ $t('sclTtl2') }}</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="ma-0 pa-0 mt-2" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="pr-4" cols="3">
                <div class="">
                    <v-img class="rounded-xl" src="../../assets/sub/birthdayParty1.png" alt="요양원 생일파티1" :aspect-ratio="16/9"></v-img>
                </div> 
            </v-col>
            <v-col class="pl-4" cols="3">
                <div class="">
                    <v-img class="rounded-xl" src="../../assets/sub/birthdayParty2.png" alt="요양원 생일파티2" :aspect-ratio="16/9"></v-img>
                </div> 
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="ma-0 pa-0 my-10" no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="2">
                <div class="font-weight-bold" style="font-size: 1.4rem; text-align: center;">{{ $t('sclSubTtl3') }}</div> 
            </v-col>
            <v-col class="d-flex align-center" cols="6">
                <div class="font-weight-bold">
                    {{ $t('sclSubCntnt3') }}
                </div>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'SclCntrDetail',
        
    components: {

    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{

    },
        
    methods: {

    },
    
    data: () => ({

    }),
};
</script>