<template>
    <div>
        <v-row class="ma-0 pa-0 py-8" no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="11">
                <div class="px-2">{{ $t('corp3') }}</div>
                <div class="px-2 pointColor--text font-weight-bold fontSizeOneDotEight">
                    {{ $t('corpAddr3') }}
                </div>
                <v-divider class="mb-4 mt-3"></v-divider>
                <div>
                    <v-img class="rounded-xl" src="../../assets/sub/photo_hwasung.jpg" alt="화성 마더링" :aspect-ratio="4/3"></v-img>
                </div>
                <v-divider class="mb-4 mt-3"></v-divider>
                <v-row class="pa-0 px-2" no-gutters>
                    <v-col class="" cols="11">
                        <span v-if="$vuetify.breakpoint.mobile == false"
                            class="ml-1 menuTxrColor--text font-weight-medium">
                            {{ $t('corpCntnt3') }}
                        </span>
                        <span v-else-if="$vuetify.breakpoint.mobile == true"
                            class="ml-1 menuTxrColor--text font-weight-medium fontSizeOne">
                            {{ $t('corpCntnt3') }}
                        </span>
                    </v-col>
                </v-row>
                <v-divider class="mt-4"></v-divider>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="ma-0 pa-0 py-8" no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="11">
                <div class="px-2">{{ $t('corp1') }}</div>
                <div class="px-2 pointColor--text font-weight-bold fontSizeOneDotEight">
                    {{ $t('corpAddr1') }}
                </div>
                <v-divider class="mb-4 mt-3"></v-divider>
                <div>
                    <v-img class="rounded-xl" src="../../assets/sub/photo_suwon.jpg" alt="수원 마더링" :aspect-ratio="4/3"></v-img>
                </div>
                <v-divider class="mb-4 mt-3"></v-divider>
                <v-row class="pa-0 px-2" no-gutters>
                    <v-col class="" cols="11">
                        <span v-if="$vuetify.breakpoint.mobile == false"
                            class="ml-1 menuTxrColor--text font-weight-medium">
                            {{ $t('corpCntnt1') }}
                        </span>
                        <span v-else-if="$vuetify.breakpoint.mobile == true"
                            class="ml-1 menuTxrColor--text font-weight-medium fontSizeOne">
                            {{ $t('corpCntnt1') }}
                        </span>
                    </v-col>
                </v-row>
                <v-divider class="mt-4"></v-divider>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="ma-0 pa-0 py-8" no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="11">
                <div class="px-2">{{ $t('corp2') }}</div>
                <div class="px-2 pointColor--text font-weight-bold fontSizeOneDotEight">
                    {{ $t('corpAddr2') }}
                </div>
                <v-divider class="mb-4 mt-3"></v-divider>
                <div>
                    <v-img class="rounded-xl" src="../../assets/sub/photo_yongin.jpg" alt="용인 마더링" :aspect-ratio="4/3"></v-img>
                </div>
                <v-divider class="mb-4 mt-3"></v-divider>
                <v-row class="pa-0 px-2" no-gutters>
                    <v-col class="" cols="11">
                        <span v-if="$vuetify.breakpoint.mobile == false"
                            class="ml-1 menuTxrColor--text font-weight-medium">
                            {{ $t('corpCntnt2') }}
                        </span>
                        <span v-else-if="$vuetify.breakpoint.mobile == true"
                            class="ml-1 menuTxrColor--text font-weight-medium fontSizeOne">
                            {{ $t('corpCntnt2') }}
                        </span>
                    </v-col>
                </v-row>
                <v-divider class="mt-4"></v-divider>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'AflFrm',
        
    components: {

    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        
    },
    
    data: () => ({

    }),
};
</script>