<template>
    <v-card
        class="" min-width="288" max-height="300" v-scroll="handleScroll"
        :img="rfctData.img" @mouseover="onCardOverEvent()" @mouseleave="outCardOverEvent()"
        width="288" height="300" elevation="0" rounded="lg">
        <transition name="fade" mode="in-out">
            <div v-if="!hover" class="white--text font-weight-bold d-flex justify-center"
                style="position: absolute; bottom: 16px; left: 0px; z-index: 3; font-size: 1.2rem !important; width: 100%;">
                {{ rfctData.title }}
            </div>
        </transition>
        <div
            class="pointColor rounded-lg rounded-t-0 serviceCardMobile"
            style="position: absolute; bottom: 0px;">
        </div>
        <v-expand-transition mode="out-in">
            <div v-if="hover" style="position: absolute; bottom: 60px;"
                class="rounded-lg rounded-b-0 serviceCardMobile--active pt-4">
                <div class="white--text font-weight-black" style="text-align: center; font-size: 1.6rem;">
                    {{ rfctData.title }}
                </div>
                <v-divider class="white" style="padding: 3px 0px; margin: 18px 130px 18px 130px;"></v-divider>
                <div class="white--text font-weight-Thin mx-4" style="text-align: center; font-size: 1rem !important;">
                    {{ rfctData.detail }}
                </div>
                <div class="d-flex justify-center align-center mt-3">
                    <v-btn
                        class="ma-2" color="white" @click="routerAction(rfctData.path)"
                        outlined rounded>
                        자세히보기
                    </v-btn>
                </div>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
export default {
    name: 'MobileSvcCard',
        
    components: {

    },
    
    props: {
        rfctData: { type: Object, default: new Object() }
    },

    created: function () {
       
    },

    mounted: function () {
        this.$nextTick(function () {

        })
    },

    destroyed: function () {
        window.removeEventListener('scroll', () => { console.log('destroyed') })
    },

    computed: {

    },

    watch:{ 
        'eleScrollTop': function () {
            //console.log(this.eleScrollTop)
            if(this.eleScrollTop > (this.rfctData.index * 180) && this.eleScrollTop < (this.rfctData.index * 180 + 240))
                this.onCardOverEvent()
            else if(this.eleScrollTop < (this.rfctData.index * 180))
                this.outCardOverEvent()
        },
    },
        
    methods: {
        onCardOverEvent: function () {
            this.hover = true
        },
        outCardOverEvent: function () {
            this.hover = false
        },
        handleScroll: function () {
            this.eleScrollTop = window.scrollY
        },
        routerAction: function (path) {
            this.$router.push({ name: path, });
        },
    },
    
    data: () => ({
        hover: false,
        eleScrollTop: 0.
    }),
};
</script>

<style>
    .serviceCardScroll { overflow: scroll; min-height: 100%; max-height: 100%; overflow-y: auto; }
    .serviceCardMobile { width: 288px; height: 60px; opacity: 0.8; }
    .serviceCardMobile--active { width: 288px; height: 240px; background-color: rgba( 247, 174, 34, 0.8 ); }

    .fade-enter { opacity: 0; }

    .fade-enter-active { transition: opacity 0.2s step-end; }

    .fade-leave-active { transition: opacity 1ms step-start; }
    .fade-leave-to { opacity: 0; }
</style>