<template>
    <v-navigation-drawer
        v-model="drawer" class="rounded-lg rounded-r-0 elevation-0" disable-resize-watcher right app>
        <div class="d-flex align-center py-5 pl-5 pr-3 pointColor">
            <span class="font-weight-bold white--text" style="font-size: 1.3rem;">해리지앤디</span>
            <v-spacer></v-spacer>
            <v-btn class="pt-1" color="white" @click="drawer=false" icon>
                <v-icon size="28">mdi-close</v-icon>
            </v-btn>
        </div>
        <v-divider></v-divider>
        <v-list class="pa-0">
            <v-list-group
                v-for="item in drawerList"
                :key="item.title"
                v-model="item.active"
                :prepend-icon="item.action"
                no-action>
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium" v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                </template>
        
                <v-list-item
                    v-for="child in item.items"
                    :key="child.title" @click="routerAction(child.name)">
                    <v-list-item-content>
                        <v-list-item-title v-text="child.title"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
        </v-list>
        <v-divider></v-divider>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'CmMobileDrawer',
        
    components: {

    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {
        getMobileMode: function (){
            return this.$vuetify.breakpoint.mobile
        },
    },

    watch:{ 
        getMobileMode: function (value) {
            this.drawer = false
            console.log('mobile mode: ' + value)
        },
    },
        
    methods: {
        routerAction: function (path) {
            this.$router.push({ name: path, });
        },
    },
    
    data: () => ({
        drawer: false,
        drawerList: [
            {
                action: 'mdi-desktop-classic',
                active: false,
                items: [
                    { title: '프로그램 소개', name: 'ITPrg' },
                ],
                title: 'IT 개발',
            },
            {
                action: 'mdi-silverware-fork-knife',
                active: false,
                items: [
                    { title: '해리푸드', name: 'HarryFood' },
                ],
                title: '위탁급식',
            },
            {
                action: 'mdi-human-cane',
                active: false,
                items: [
                    { title: '요양원 개발', name: 'NrsnHomeDvlp' },
                    { title: '실버사업', name: 'SlvrBsns' },
                ],
                title: '실버 Biz',
            },
            {
                action: 'mdi-hand-heart-outline',
                active: false,
                items: [
                    { title: '사회공헌활동', name: 'SclCntrCnfcr' },
                ],
                title: '사회공헌',
            },
            {
                action: 'mdi-office-building-outline',
                active: false,
                items: [
                    { title: '해리지앤디 소개', name: 'CmpInt' },
                    { title: '연혁', name: 'CmpHstr' },
                    { title: '관계사 현황' , name: 'AflFrm' },
                    { title: '오시는길', name: 'CmpWayCome' },
                ],
                title: '회사소개',
            },
        ],
    }),
};
</script>