<template>
    <div class="px-2">
        <div>
            <v-icon class="menuTxrColor--text" style="padding-bottom: 2px;" size="20">mdi-subway-variant</v-icon>
            <span class="ml-1 menuTxrColor--text font-weight-medium">지하철</span>
        </div>
        <div v-for="(item, i) in info" :key="i">
            <v-row class="pa-0 mt-2 fontSizeOne" v-if="item.mode == 'subway'" no-gutters>
                <v-col class="" cols="3">{{ item.how }}&#58;</v-col>
                <v-col class="" cols="9">
                    <div class="menuTxrColor--text">{{ item.way }}</div>
                </v-col>
            </v-row>
        </div>

        <div class="mt-3">
            <v-icon class="menuTxrColor--text" style="padding-bottom: 2px;" size="20">mdi-bus</v-icon>
            <span class="ml-1 menuTxrColor--text font-weight-medium">버스</span>
        </div>
        <div v-for="(item, i) in info" :key="info.length + i">
            <v-row class="pa-0 mt-2 fontSizeOne" v-if="item.mode == 'bus'" no-gutters>
                <v-col class="" cols="3">
                    {{ item.how }}&#58;
                </v-col>
                <v-col class="" cols="9">
                    <div class="menuTxrColor--text">{{ item.way }}</div>
                </v-col>
            </v-row>
        </div>

        <div class="mt-3" v-if="airBusChk != -1">
            <v-icon class="menuTxrColor--text" style="padding-bottom: 2px;" size="20">mdi-airplane-landing</v-icon>
            <span class="ml-1 menuTxrColor--text font-weight-medium">공항버스</span>
        </div>
        <div v-for="(item, i) in info" :key="(info.length*2) + i">
            <v-row class="pa-0 mt-2 fontSizeOne" v-if="item.mode == 'airportLimousine'" no-gutters>
                <v-col class="" cols="3">{{ item.how }}&#58;</v-col>
                <v-col class="" cols="9">
                    <div class="menuTxrColor--text">{{ item.way }}</div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TraicInfoMobile',
        
    components: {

    },

    props: {
        trafficData: { type: Array, default: new Array() },
        target: { type: Number, default: -1 }
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.dataControll()
        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        dataControll: function () {
            if(this.target == -1) return

            this.info = this.trafficData.filter(v => v.index == this.target)
            this.airBusChk = this.info.findIndex(v => v.mode == 'airportLimousine')
        },
    },
    
    data: () => ({
        info: null,
        airBusChk: -1,
    }),
};
</script>