<template>
    <v-row class="ma-0 pa-0 overflow-x-hidden" no-gutters>
        <v-col class="d-flex justify-center mt-16" cols="12">
            <div :class="[$vuetify.breakpoint.mobile == false ? 'mt-10' : '']">
                <v-row class="ma-0 pa-0 my-5" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="" cols="12">
                        <span class="font-weight-bold pl-4" style="font-size: 2rem;">ERP 소개</span>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="ma-0 pa-0 mt-10" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col class="align-center" cols="12">
                        <div class="font-weight-bold pl-2" style="font-size: 1.6rem; border-left: 10px solid #f7ae22;">
                            기부를 통한 사회공헌
                        </div>
                        <br/>
                        <div class="font-weight-bold pl-4" style="font-size: 1.3rem;">
                            업무기록으로 빠르고 쉽게 시설평가를 받을 수 있다.
                        </div>
                        <br/>
                        <div class="font-weight-bold pl-4" style="color: #767676;">
                            번거롭게 따로따로 하지 않고 기록된 현장 업무 사항을 다이렉트로 평가 또한 손쉽게 수정, 삭제가 가능
                        </div>
                        <br/>
                        <div class="font-weight-bold pl-4" style="font-size: 1.3rem;">
                            직종별, 업무별로 관리가 가능 가능합니다.
                        </div>
                        <br/>
                        <div class="font-weight-bold pl-4" style="color: #767676;">
                            요양보호사, 간호사, 사회복지사, 물리치료사, 영양조리사, 사무국장등 업무별 권한 부여로 체계적인 움직임
                        </div>
                        <br/>
                        <div class="font-weight-bold pl-4" style="font-size: 1.3rem;">
                            PC버전, mobile버전 모두 제공해드립니다.
                        </div>
                        <br/>
                        <div class="font-weight-bold pl-4" style="color: #767676;">
                            컴퓨터, 태블릿, 스마트폰 등 여러 기기를 사용해 어디서든 업무를 볼 수 있습니다.
                        </div>
                        <br/>
                        <div class="font-weight-bold pl-4" style="font-size: 1.3rem;">
                            터치 UI 및 부드러운 전개
                        </div>
                        <br/>
                        <div class="font-weight-bold pl-4" style="color: #767676;">
                            터치 UI로 필요한 부분을 쉽게 찾고 사용하며 부드러운 이동 및 로딩으로 화면에 대한 질림을 방지
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </div>
        </v-col>
        <v-col class="d-flex justify-center" cols="12">
            <div :class="[$vuetify.breakpoint.mobile == false ? 'mt-6' : 'mt-2']" style="width: 50%;">
                <v-row class="mt-4 mb-6" no-gutters>
                    <v-col class="d-flex justify-center mb-4" :cols="$vuetify.breakpoint.mobile == false ? 4 : 12">
                    </v-col>
                    <v-col class="d-flex justify-center mb-4" :cols="$vuetify.breakpoint.mobile == false ? 4 : 12">
                    </v-col>
                    <v-col class="d-flex justify-center mb-4" :cols="$vuetify.breakpoint.mobile == false ? 4 : 12">
                    </v-col>
                </v-row>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'EtERP',
        
    components: {

    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        
    },
    
    data: () => ({

    }),
};
</script>

<style>

</style>