<template>
    <div>
        <v-row class="ma-0 pa-0 px-10 pt-5" no-gutters>
            <v-col cols="12">
                <div class="mt-5">
                    ㈜해리지앤디 ('www.harrygnd.com')은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
                </div>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 px-10 pt-5" no-gutters>
            <v-col v-for="(pcy, i) in policyTitle" :key="i" cols="12">
                <div class="mt-5">{{ i+1 }}. {{ pcy }}</div>
                <div v-for="(pcl, j) in policyList" :key="j">
                    <div class="policyBox fontSizeDotEight rounded-lg py-2 px-3 mt-2" v-if="i == pcl.index">
                        <div v-for="(con, k) in pcl.content" :key="k">
                            <div class="d-flex">
                                <span class="d-inline-block" style="width: 20px;">{{ String.fromCharCode('0x246'+k) }}</span>
                                <span class="d-inline-block" style="width: 100%;">{{ con.mmm }}</span>
                            </div>
                            <div class="pl-5" v-for="(dep, l) in con.subList" :key="l">
                                <div class="d-flex">
                                    <span class="d-inline-block" style="width: 20px;">{{ l+1 }}.</span>
                                    <span class="d-inline-block" style="width: 100%;">{{ dep.nnn }}</span>
                                </div>
                                <div class="pl-5" v-for="(lsDept, m) in dep.last" :key="m">
                                    <div class="d-flex">
                                        <span class="d-inline-block" style="width: 20px;">{{ m+1 }})</span>
                                        <span class="d-inline-block" style="width: 100%;">{{ lsDept.ooo }}</span>
                                    </div>
                                    <div class="pl-5" v-for="(nsDept, n) in lsDept.fin" :key="n">
                                        <div class="d-flex">
                                            <span class="d-inline-block" style="width: 20px;">{{ String.fromCharCode('0x24f'+(n+5)) }}</span>
                                            <span class="d-inline-block" style="width: 100%;">{{ nsDept }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 px-10 pb-10" no-gutters>
            <v-col cols="12">
                <div class="mt-5">
                    <div class="mt-5">부칙</div>
                    <div class="policyBox fontSizeDotEight rounded-lg py-2 px-3 mt-2"> 제 1조 (시행일) 본 약관은 2023년 3월 1일부터 시행합니다.</div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'PrvcPlcy',
        
    components: {

    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        
    },
    
    data: () => ({
        policyTitle: [
            '개인정보의 처리 목적',
            '개인정보의 처리 및 보유 기간',
            '처리하는 개인정보의 항목',
            '개인정보의 파기절차 및 파기방법',
            '정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항',
            '개인정보의 안전성 확보조치에 관한 사항',
            '개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항',
            '행태정보의 수집·이용·제공 및 거부 등에 관한 사항',
            '개인정보 보호책임자에 관한 사항',
            '정보주체의 권익침해에 대한 구제방법',
            '고지의 의무',
        ],
        policyList: [
            {
                content: [
                    {
                        mmm: '해리지앤디("www.harrygnd.com")은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.',
                        subList: [
                            { 
                                nnn: '홈페이지 회원가입 및 관리',
                                last: [
                                    {
                                        ooo: '회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.',
                                    },
                                ],
                            },
                            { 
                                nnn: '재화 또는 서비스 제공',
                                last: [
                                    {
                                        ooo: ' 물품배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심을 목적으로 개인정보를 처리합니다.',
                                    },
                                ],
                            },
                        ],
                    },
                ],
                index : 0
            },
            {
                content: [
                    {
                        mmm: '해리지앤디은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.',
                        subList: [],
                    },
                    {
                        mmm: '각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.',
                        subList: [
                            { 
                                nnn: '홈페이지 회원가입 및 관리',
                                last: [
                                    {
                                        ooo: '홈페이지 회원가입 및 관리와 관련한 개인정보는 수집.이용에 관한 동의일로부터<1년>까지 위 이용목적을 위하여 보유.이용됩니다.',
                                        fin: [],
                                    },
                                    {
                                        ooo: '보유근거 : 전자상거래등에서의 소비자보호에 관한 법률',
                                        fin: [],
                                    },
                                    {
                                        ooo: '관련법령 : ',
                                        fin: [
                                            '신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년',
                                            '소비자의 불만 또는 분쟁처리에 관한 기록 : 3년',
                                            '대금결제 및 재화 등의 공급에 관한 기록 : 5년',
                                            '계약 또는 청약철회 등에 관한 기록 : 5년',
                                            '표시/광고에 관한 기록 : 6개월',
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
                index : 1
            },
            {
                content: [
                    {
                        mmm: '해리지앤디 은(는) 다음의 개인정보 항목을 처리하고 있습니다.',
                        subList: [
                            { 
                                nnn: '홈페이지 회원가입 및 관리',
                                last: [
                                    {
                                        ooo: '필수항목 : 이메일, 휴대전화번호, 비밀번호, 로그인ID, 성별, 생년월일, 이름',
                                    },
                                    {
                                        ooo: '선택항목 : 자택주소, 회사전화번호, 직책, 부서, 회사명, 직업',
                                    },
                                ],
                            },
                        ],
                    },
                ],
                index : 2
            },
            {
                content: [
                    {
                        mmm: '해리지앤디  은(는) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.',
                        subList: [],
                    },
                    {
                        mmm: '개인정보 파기의 절차 및 방법은 다음과 같습니다.',
                        subList: [
                            { 
                                nnn: '회사에서 처리하는 개인정보를 파기할 때에는 다음의 방법으로 파기 합니다.',
                                last: [
                                    {
                                        ooo: '전자적 파일 형태인 경우: 복원이 불가능한 방법으로 영구삭제 ',
                                    },
                                    {
                                        ooo: '전자적 파일의 형태 외의 기록물, 인쇄물, 서면, 그 밖의 기록매체인 경우: 파쇄 또는 소각',
                                    },
                                ],
                            },
                        ],
                    },
                ],
                index : 3
            },
            {
                content: [
                    {
                        mmm: '정보주체는 ㈜해리지앤디에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.',
                        subList: [],
                    },
                    {
                        mmm: '제1항에 따른 권리 행사는㈜해리지앤디에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 ㈜해리지앤디은(는) 이에 대해 지체 없이 조치하겠습니다.',
                        subList: [],
                    },
                    {
                        mmm: '제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.',
                        subList: [],
                    },
                    {
                        mmm: '개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.',
                        subList: [],
                    },
                    {
                        mmm: '개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.',
                        subList: [],
                    },
                    {
                        mmm: '㈜해리지앤디은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.',
                        subList: [],
                    },
                ],
                index : 4
            },
            {
                content: [
                    {
                        mmm: '해리지앤디 은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.',
                        subList: [
                            { 
                                nnn: '정기적인 자체 감사 실시',
                                last: [
                                    {
                                        ooo: '개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.',
                                    },
                                ],
                            },
                            { 
                                nnn: '개인정보 취급 직원의 최소화 및 교육',
                                last: [
                                    {
                                        ooo: '개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.',
                                    },
                                ],
                            },
                            { 
                                nnn: '해킹 등에 대비한 기술적 대책',
                                last: [
                                    {
                                        ooo: '해리지앤디("www.harrygnd.com")은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.',
                                    },
                                ],
                            },
                            { 
                                nnn: '개인정보의 암호화',
                                last: [
                                    {
                                        ooo: '이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.',
                                    },
                                ],
                            },
                        ],
                    },
                ],
                index : 5
            },
            {
                content: [
                    {
                        mmm: '㈜해리지앤디 은(는) 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.',
                        subList: [],
                    },
                    {
                        mmm: '쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.',
                        subList: [
                            { 
                                nnn: '쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.',
                                last: [],
                            },
                            { 
                                nnn: '쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.',
                                last: [],
                            },
                            { 
                                nnn: '쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.',
                                last: [],
                            },
                        ],
                    },
                ],
                index : 6
            },
            {
                content: [
                    {
                        mmm: '행태정보의 수집·이용·제공 및 거부등에 관한 사항',
                        subList: [
                            { 
                                nnn: '행태정보의 수집·이용·제공 및 거부등에 관한 사항',
                                last: [
                                    {
                                        ooo: '<개인정보처리자명>은(는) 온라인 맞춤형 광고 등을 위한 행태정보를 수집·이용·제공하지 않습니다.',
                                    },
                                ],
                            },
                        ],
                    },
                ],
                index : 7
            },
            {
                content: [
                    {
                        mmm: '㈜해리지앤디 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.',
                        subList: [
                            { 
                                nnn: '개인정보 보호책임자',
                                last: [
                                    {
                                        ooo: '성명 : 정유경, 직책 : 이사, 연락처 : 031-546-0405, ※ 개인정보 보호 담당부서로 연결됩니다.',
                                    },
                                ],
                            },
                        ],
                    },
                ],
                index : 8
            },
            {
                content: [
                    {
                        mmm: '정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.',
                        subList: [
                            { 
                                nnn: '개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)',
                                last: [],
                            },
                            { 
                                nnn: '개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)',
                                last: [],
                            },
                            { 
                                nnn: '대검찰청 : (국번없이) 1301 (www.spo.go.kr)',
                                last: [],
                            },
                            { 
                                nnn: '경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)',
                                last: [
                                    {
                                        ooo: '「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.',
                                    },
                                    {
                                        ooo: '행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.',
                                    },
                                ],
                            },
                        ],
                    },
                ],
                index : 9
            },
            {
                content: [
                    {
                        mmm: '현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 "공지사항"을 통해 고지할 것입니다',
                        subList: [],
                    },
                ],
                index : 10
            },
        ],
    }),
};
</script>