import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { en, ko } from 'vuetify/es5/locale';

Vue.use(Vuetify);

export default new Vuetify({
    // 언어 설정
    lang: {
        locales: { ko, en },
        current: 'ko',
    },
    //사용자 아이콘 설정
    icons: {
        iconfont: 'mdi',
    },
    //테마 설정
    theme: {
        dark: false,
        default: 'light',
        themes: {
            light: {
                logoColor: '#e46d0a',
                menuTxrColor: '#343434',
                bgColor1: '#fff9ef',
                bgColor2: '#f7f7f7',
                pointColor: '#f7ae22',
                ckTxtColor: '#d9d9d9',
                subDeco1: '#fdedde',
                subDeco2: '#fcf5ef',
                grey0999: '#999',
            },
            dark: {
                primaryText: '#9e9e9e',
                secondaryText: '#e75546',
                thirdText: '#fff',
                fourthText: '#4b82f2',
                subText: '#606e79',
                mainText: '#9c9996',
                menuDiv: '#4f4f4f',
                backgroundColor: '#121212',
            },
        },
    },
});
